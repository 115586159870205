import * as elchspuckeService from './../../elchspucke-tuicom.service';
import * as service from './service';

export default function () {

    let featureName = 'no-results';
    let dataFilteredEvent;
    let previousResultCount;

    if (window.tuiCottonBall) {

        window.tuiCottonBall.cuddleMeIf(featureName, '*', 'The search parameters did change.', function (component, scope, event, data) {
            if (data && data.filterState && data.newValue && data.oldValue) {
                dataFilteredEvent = data;
            }
        });

        // listen to Angular App changes to track no results
        window.tuiCottonBall.cuddleMeIf(featureName, '*', 'IBE search result rendered.', function (component, scope, event, searchResultData) {
            if (searchResultData && searchResultData.data && searchResultData.searchScope && searchResultData.stepNumber) {
                if (searchResultData.data.resultCount === 0 && previousResultCount !== 0) {
                    let noResultFilter;
                    if (dataFilteredEvent) {
                        noResultFilter = service.getFilterToNoResults(dataFilteredEvent.oldValue, dataFilteredEvent.newValue);
                        elchspuckeService.trackEvent('feature', 'empty_result_detected_through_filter', searchResultData.searchScope + searchResultData.stepNumber, noResultFilter);
                        window.tuiCottonBall.broadcast('mojo', 'Access denied, no holidays available.', noResultFilter);
                    } else {
                        elchspuckeService.trackEvent('feature', 'empty_result_detected', searchResultData.searchScope + searchResultData.stepNumber);
                        window.tuiCottonBall.broadcast('mojo', 'Access denied, no holidays available.', {});
                    }
                } else if (previousResultCount === 0) {
                    window.tuiCottonBall.broadcast('mojo', 'Access denied, no holidays available.', {});
                }

                previousResultCount = searchResultData.data.resultCount;
            }
        });
    }

    return featureName;
}
