import * as toggles from '../toggles';

export default function () {
    if (toggles.isActive('handle-bu-utag-view')) {
        try {
            window.ibe.featureEnabled.tracking.buUtagView = false;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(new Error(`Error: featureEnabled.tracking.buUtagView ${error}`));
        }
    }

    return 'ibe-feature-toggles';
}
