/**
 * @param  {string} language   the language to use in ISO format like de_DE
 * @param  {string} id         the id to be set for the html element, optional
 * @param  {string} eventScope the rooms panels event scope configured in it's dataset, defaults to *
 * @return {object}            a rooms panel html element with the TUICOM tenant and de_DE as language
 */
const createRoomsPanelDiv = (language, priceFormat, id, eventScope) => {
    var html = document.createElement('div');
    html.className = 'tui-component tui-component-rooms-panel tui-design';
    if (id) {
        html.id = id;
    }
    html.dataset.eventScope = eventScope ? eventScope : '*';
    html.dataset.tenancy = 'TUICOM';
    html.dataset.variant = 'grouped';
    html.dataset.lang = language;
    html.dataset.priceFormat = priceFormat;
    html.dataset.autostart = false;
    // use the following line for local testing
    //html.dataset.url = 'http://localhost:1337/ui/rooms-panel.js';
    html.dataset.url = 'https://api.tui.com/ml/rooms-panel/ui/';

    html.style.marginBottom = '20px';
    
    var roomsPanelSection = document.getElementById('tui-component-roompanel');
    if (roomsPanelSection) {
        roomsPanelSection.style.padding = '20px';    
    }
    
    return html;
};

const extractPriceType = (ibeSearchParams) => {
    if (ibeSearchParams !== Object(ibeSearchParams) || ibeSearchParams instanceof Array) {
        return false;
    }

    const filters = ibeSearchParams.filters;
    if (filters !== Object(filters) || filters instanceof Array) {
        return false;
    }

    return (filters.showTotalPrice === true) ? 'TOTAL' : 'PER_PERSON';
};

const getPanelInjectionSelector = () => {
    return '#tui-after-room-description-list';
};

const constructIgnitionData = (commonSearchModel, priceType, roomsPanelElement, showPrice) => {
    if (!commonSearchModel ||
        !(commonSearchModel.giataCodes instanceof Array && commonSearchModel.giataCodes.length > 0) ||
        (priceType !== 'PER_PERSON' && priceType !== 'TOTAL') ||
        !roomsPanelElement || !roomsPanelElement.classList || !roomsPanelElement.classList.contains('tui-component-rooms-panel')) {
        return false;
    }

    return {
        rooms: commonSearchModel.rooms,
        giataId: commonSearchModel.giataCodes[0],
        searchScope: commonSearchModel.searchScope,
        priceType,
        showPrice,
        globalSearch: JSON.stringify(commonSearchModel),
        element: roomsPanelElement
    };
};

const searchModelIsProcessable = (commonSearchModel) => {
    if (commonSearchModel !== Object(commonSearchModel) || commonSearchModel instanceof Array ||
        !(commonSearchModel.rooms instanceof Array)) {
        return false;
    }

    // only process single room
    return commonSearchModel.rooms.length === 1;
};

export {
    createRoomsPanelDiv,
    getPanelInjectionSelector,
    extractPriceType,
    constructIgnitionData,
    searchModelIsProcessable
}
