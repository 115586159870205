import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

import * as toggles from '../toggles';

export default (config) => {
    const version = config.getVersion();
    const stage = config.getStage();

    const applicationId = 'de82807b-ae07-4be7-b773-b4d3dad179f0';
    const clientTokenRum = 'pub57b64037fdd2312b51625520be0855d2';
    const clientTokenLog = 'pubab430f606e94776dd7e08ad35a5bf585';
    const site = 'datadoghq.eu';

    if (toggles.isActive('dataDog')) {
        let RumSampling = parseInt('10') || 100;
        let LogSampling = parseInt('10') || 100;

        datadogRum.init({
            applicationId,
            clientToken: clientTokenRum,
            site,
            service: 'cr-bau---tui.com',
            env: stage,
            version: version,
            sampleRate: RumSampling,
            trackInteractions: true
        });

        datadogLogs.init({
            clientToken: clientTokenLog,
            site,
            forwardErrorsToLogs: true,
            forwardConsoleLogs: ["debug", "info", "warn", "error"],
            sampleRate: LogSampling
        });

        datadogLogs.addLoggerGlobalContext('businessunit', 'web-domain');
        datadogLogs.addLoggerGlobalContext('subunit', 'cr-bau-tuicom');
    }
};
