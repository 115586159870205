import { createHTMLNode } from '../../util.js';

const loadImageGalleryMFE = (awsCdnUrl) => {
    new Promise((resolve, reject) => {
        if (document.getElementById('image-gallery-script')) {
            return resolve();
        }

        const $componentJs = document.createElement('script');
        $componentJs.setAttribute('src', `${awsCdnUrl}image-gallery/v2/image-gallery.iife.js`);
        $componentJs.setAttribute('type', 'module');
        $componentJs.setAttribute('id', 'image-gallery-script');

        $componentJs.onload = () => resolve();
        $componentJs.onerror = (error) => reject(error);

        document.body.appendChild($componentJs);
    })
        // eslint-disable-next-line no-console
        .catch((error) => console.error('image-gallery script could not be loaded', error));
};

const buildImageGalleryElement = (tenant, startIndex) => {
    let imageGalleryElement = document.querySelector('tui-image-gallery');

    if (!imageGalleryElement) {
        imageGalleryElement = createHTMLNode('tui-image-gallery');
    }

    imageGalleryElement.setAttribute('data-mode', 'light');
    imageGalleryElement.setAttribute('data-theme', 'tui-light');
    imageGalleryElement.setAttribute('variant', 'cinema');
    imageGalleryElement.setAttribute('tenant', tenant);
    imageGalleryElement.setAttribute('cinema-hide-categories', true);
    imageGalleryElement.setAttribute('start-index', startIndex);

    imageGalleryElement.addEventListener('tui-image-gallery.cinema-view-closed', () => {
        imageGalleryElement.remove();
    });

    return imageGalleryElement;
};

const buildImageGalleryData = (data) => {
    const imageGalleryData = {
        title: data.room.title,
        categories: []
    };

    imageGalleryData.media = data.room.images.map((img) => ({
        title: img.title,
        url: img.url,
        category: { code: '', label: '' },
        favorite: img.rating === '5',
        mediumType: 'IMAGE'
    }));

    return imageGalleryData;
};

const openImageGallery = (tenant, data) => {
    const imageGalleryElement = buildImageGalleryElement(tenant, data.clickedImageIndex);
    const roomPanel = document.querySelector('tui-room-panel');
    roomPanel.parentNode.appendChild(imageGalleryElement);
    imageGalleryElement.setData(buildImageGalleryData(data));
};

const updateRoomPanel = async ({ ibeState }) => {
    // Take values from ibe filter
    const { minPrice, maxPrice, operators, searchScope, startDate, endDate, duration, departureAirports } =
        ibeState.filters;

    let formattedStartDate = '';
    let formattedEndDate = '';

    try {
        formattedStartDate = new Date(startDate).toISOString().split('T')[0];
        formattedEndDate = new Date(endDate).toISOString().split('T')[0];
    } catch (e) {
        console.log("Couldn't parse dates");
    }

    const url = new URL(window.location.href);
    const searchParams = url.searchParams;

    // Take raw values from search
    const travellers = searchParams.get('travellers');

    const roomPanel = await new Promise((resolve) => {
        let interval = null;
        let waitingTime = 300;
        let intervalTime = 50;

        const waitForElement = () => {
            const el = document.querySelector('tui-room-panel');
            waitingTime -= intervalTime;

            if (waitingTime <= 0) {
                clearInterval(interval);
                return resolve(null);
            }

            if (el) {
                clearInterval(interval);
                return resolve(el);
            }
        };

        interval = setInterval(waitForElement, intervalTime);
    });

    roomPanel?.updateAttributes({
        minPrice,
        maxPrice,
        tourOperators: operators?.join(',') ?? '',
        searchScope,
        duration,
        departureAirports: departureAirports?.join(';') ?? '',
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        travellers
    });
};

export { loadImageGalleryMFE, openImageGallery, updateRoomPanel };
