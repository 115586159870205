import _isEqual from 'lodash.isequal';

const getFilterToNoResults = function (oldValue, newValue) {

    if ((!oldValue || typeof oldValue !== 'object' || Object.keys(oldValue).length === 0) ||
        (!newValue || typeof newValue !== 'object' || Object.keys(newValue).length === 0)) {
        return false;
    }

    if (_isEqual(oldValue, newValue)) {
        return 'I am not unique :-(';
    }

    let filter = '';

    for (let key in oldValue) {
        if (!_isEqual(oldValue[key], newValue[key])) {
            if (Array.isArray(oldValue[key]) || Array.isArray(newValue[key])) {
                for(let item in newValue[key]) {
                    if (oldValue[key].indexOf(newValue[key][item]) === -1) {
                        filter = getFilter(key, [newValue[key][item]]);
                        break;
                    }
                }
            } else if (oldValue[key] instanceof Object || newValue[key] instanceof Object) {
                Object.getOwnPropertyNames(newValue[key]).forEach(function (val) {
                    if (!_isEqual(oldValue[key][val], newValue[key][val])) {
                        filter = getFilter(val, newValue[key][val]);
                    }
                });
            } else {
                filter = getFilter(key, newValue[key]);
            }
            break;
        }
    }

    return filter;
};

const getFilter = function (key, value) {
    return key + ': ' + value;
};

export {
    getFilterToNoResults
}