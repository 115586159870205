"use strict";
import * as HotelCardsService from './service';
import * as ElchspuckeService from '../../elchspucke-tuicom.service';

export default function (config) {
    const plattformUrl = config.getPlattformHost();

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Someone pushed my button.', function (component, scope, event, data) {
        HotelCardsService.getTUICOM_PTH_URL(plattformUrl, component, scope, event, data);
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Build URLS', HotelCardsService.buildUrlsCallback.bind(this, plattformUrl));

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Hotelcards are rendered.', function(component, scope, event, data) {
        var eventScopes = data.scope;
        if (eventScopes && eventScopes instanceof Array && eventScopes.length > 0) {

            if (eventScopes.indexOf(HotelCardsService.hotelCardsEventScope().historyCards) > -1) { // we are rendering the history cards
                ElchspuckeService.trackEvent('feature', HotelCardsService.hotelCardsEventScope().historyCards, 'display', data.giataIds);
            }
            if (eventScopes.indexOf(HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_Home) > -1) { // we are rendering the history cards
                ElchspuckeService.trackEvent('feature', HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_Home, 'display', data.giataIds);
            }
            if (eventScopes.indexOf(HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_SINGLE_Home) > -1) { // we are rendering the history cards
                ElchspuckeService.trackEvent('feature', HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_SINGLE_Home, 'display', data.giataIds);
            }
            if (eventScopes.indexOf(HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_COUPLE_Home) > -1) { // we are rendering the history cards
                ElchspuckeService.trackEvent('feature', HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_COUPLE_Home, 'display', data.giataIds);
            }
            if (eventScopes.indexOf(HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_FAMILY_Home) > -1) { // we are rendering the history cards
                ElchspuckeService.trackEvent('feature', HotelCardsService.hotelCardsEventScope().HC_CB_topHotels_FAMILY_Home, 'display', data.giataIds);
            }
        }
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Track the rendering of the history cards.', function(component, scope, event, data){
        ElchspuckeService.trackEvent('feature', 'historycards', 'display', data);
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'IBE search result rendered.', function(component, scope, event, data){

        window.tuiCottonBall.broadcast('a-b-test', 'You could start a hotel-cards A/B test using IBE search result.', data);
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'Tell me about campaigns.', function (component, scope, event, data) {
        window.tuiCottonBall.pleaseTakeCareOf('campaign', scope, 'Call me back with your campaign configuration.', HotelCardsService.buildCampaignData.bind(this, data));
    });

    window.tuiCottonBall.cuddleMeIf('hotel-cards', '*', 'The view settings did change.', function(component, scope, event, data){
        HotelCardsService.updateCards(data);
    });

    return 'hotel-cards';
}
