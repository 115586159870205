/**
 * This function changes the target of the links inside the hotel list / PT3 to target='_self'
 * This forces the application to send a proper GET request to load the follwing PT4 instead of doing a ajax request.
 * Reason: we want the PT4 request to be conducted by varnish to decide whether or not the client is a mobile device
 * and whether or not the PT4 is delivered as the mobile version aka Blackbird
 */
export default function () {

    // Check if client is a mobile device
    var ios = document.querySelector('.ios');
    var android = document.querySelector('.android');

    // Do nothing if not
    if(!ios && !android) {
        return null;
    }

    // Check if the current page is a PT3 containing a hotel list
    var path = window.location.pathname;

    if (path && path.match(/\/suchen\/hotels\//)) {

        // set target of links to _self
        var modifyLinks = function () {
            var links = document.querySelectorAll('#t-hotel-list article a');
            if (links && links.length > 0) {
                links.forEach(function (link) {
                    if (link.href.indexOf('suchen/angebote') > -1) {
                        link.target = '_self';
                    }
                });
            }
        };
        
        // initial modification
        // waiting for the hotelList to be loaded
        var counter = 0;
        var hotelListInterval = setInterval(function () {
            var links = document.querySelectorAll('#t-hotel-list article a');
            if (links && links.length > 0) {
                clearInterval(hotelListInterval);
                modifyLinks();
            } else {
                if (counter > 50) {
                    clearInterval(hotelListInterval);
                }
                ++counter;
            }
        }, 500);

        // modify links after document has updated 
        // since the pt3 is a single page application 
        // and the hotel list might be changed after the initial load
        document.body.onchange = modifyLinks;
    }
}    
