const linkBuilder = require('../../link-builder.service').default;

const generateCallToActionPTH4 = function(plattformUrl, comparisonItem) {
    let urlObject = null;
    if (comparisonItem && comparisonItem.offer && comparisonItem.offer.hotel && comparisonItem.searchParameters) {
        const hotel = comparisonItem.offer.hotel;
        const destination = {
            giataId: hotel.giataId,
            name: hotel.name
        };
        const parameters = comparisonItem.searchParameters;
        let filters = {};

        if (parameters.startDate) {
            filters.startDate = new Date(parameters.startDate);
        }

        if (parameters.endDate) {
            filters.endDate = new Date(parameters.endDate);
        }

        if (parameters.duration) {
            filters.duration = mapComparatorDurationToIbeFilters(parameters.duration);
        }

        if (parameters.rooms) {
            filters.travellers = mapComparatorRoomsToIBEFilters(parameters.rooms);
            const boardAndRoomTypes = mapBoardCodeAndRoomCodeToIBEFilters(parameters.rooms);
            filters.boardTypes = boardAndRoomTypes.boardTypes;
            filters.roomTypes = boardAndRoomTypes.roomTypes;
        }

        if (parameters.airports) {
            filters.departureAirports = parameters.airports;
        }

        const url = new URL(plattformUrl + linkBuilder.generateUrl(destination, filters));
        urlObject = {giataId: hotel.giataId, url: url};
    }
    return urlObject;
};

const mapComparatorRoomsToIBEFilters = (rooms) => {
    if (Array.isArray(rooms)) {
        return rooms.map(room => {
            return {
                adults: room.numberOfAdults,
                children: room.childAges
            };
        })
    }
};

const mapComparatorDurationToIbeFilters = (duration) => {
    if (duration && Array.isArray(duration)) {
        if (duration.length === 0) {
            return '3';
        } else if (duration.length < 2) {
            return duration.join();
        } else {
            return duration[0] + '-' + duration[duration.length-1];
        }
    }
};

const mapBoardCodeAndRoomCodeToIBEFilters = (rooms) => {
    if (Array.isArray(rooms) && rooms.length > 0) {
        return {
            boardTypes: rooms[0].boardCodes,
            roomTypes: rooms[0].roomCodes
        }
    }
};

export {
    generateCallToActionPTH4
}
