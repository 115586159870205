/* eslint no-unused-labels: 0 */

export default {
    getComponentsHostAndPath: () => 'https://cloud.tui.com/cdn/mojo/current/tuicom/',
    getLanguage: () => 'de_DE',
    getCurrency: () => 'de',
    getTenant: () => 'TUICOM',
    getSearchDiggerTenant: () => 'tui.com',
    getVersion: () =>  'tuicom',
    getCampaignServiceCountry: () => 'DE',
    getCouponTemplatePath: () => 'fileadmin/tuicom/coupons/coupon_config_com.json',
    getCouponServiceUrl: () => 'https://api.cloud.tui.com/coupon/current/TUICOM'
};
