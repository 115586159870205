import * as mojoService from '../elchspucke-tuicom.service';
import { pageIs, getNestedObject } from '../util.js';

/**
 * Mojo EventPayload
 *
 * [{
 *     offerIndex: number
 *     flightAttribute: string
 *     legIndex: number
 *     legFlightNumber: string
 *     travellerIndex: number
 *     seat: {
 *         identifier: string
 *         row: number
 *         column: string
 *         status: string
 *         price: {
 *             amount: number
 *             originalAmount: number | null
 *             currencyCode: string
 *         }
 *         seatProperties: Array<string>
 *         seatCategory: string
 *         isSelected: boolean
 *     }
 * },
 * {
 *   ...
 * }]
 */

export default () => {
    /**
     * trackSeatmap
     *
     * @param  {string} component the component raising the event
     * @param  {string} scope     the scope the event is raised in
     * @param  {string} event     the name of the event
     * @param  {object} data      EventPayload
     */
    function trackSeatmap(component, scope, event, data) {
        const trackingCases = {
            'BU1': trackUpdatedReservations,
            'BU3': trackBookedReservations
        };

        trackingCases[scope].call(this, data);
    }

    /**
     * trackUpdatedReservations
     *
     * @param  {object} data EventPayload
     */
    function trackUpdatedReservations(data) {
        const reservationsCount = data.length;
        const reservationData = accumulateData(data);

        if (data.length === 0) {
            mojoService.trackEvent('feature', 'seatmap', 'delete', '1');
        }

        mojoService.trackEvent('feature', `seat_${reservationsCount}_${reservationData.seatCategoryArray.join()}`, 'select', reservationData.seatPrice);
    }

    /**
     * trackBookedReservations
     *
     * @param  {object} data EventPayload
     */
    function trackBookedReservations(data) {
        const reservationData = accumulateData(data);

        mojoService.trackData('seat_name', reservationData.seatCategoryArray.join());
        mojoService.trackData('seat_price', reservationData.seatPrice);

        window.tuiCottonBall.publish('tracking', 'BU3', 'Trigger utag view.', {});
    }

    /**
     * accumulateData
     *
     * @param  {type} data EventPayload
     * @return {object}    {
     *                          seatCategoryArray: [seatCategoryName-seatCategoryCount, ...]
     *                          seatPrice: number
     *                      }
     */
    function accumulateData(data) {
        let seatCategoryCount = {};
        let seatCategoryArray = [];
        let seatPrice = 0;

        data.forEach((entry) => {
            const { seat } = entry;

            if (seat) {
                seatPrice = seatPrice + getSeatPrice(seat);

                let seatCount = seatCategoryCount[seat.seatCategory];

                if (seatCount) {
                    seatCategoryCount[seat.seatCategory] = seatCount + 1;
                } else {
                    seatCategoryCount[seat.seatCategory] = 1;
                }
            }
        });

        Object.keys(seatCategoryCount).forEach((key) => {
            seatCategoryArray.push(`${key}-${seatCategoryCount[key]}`);
        });

        return {
            seatCategoryArray,
            seatPrice
        }
    }

    /**
     * getSeatPrice
     *
     * @param  {object} seat data.seat
     * @return {number} seat price
     */
    function getSeatPrice(seat) {
        let price = 0;

        if (seat && seat.price) {
            price = seat.price.amount;
        }

        return price;
    }

    function waitForResultsSeatMap(iterations) {

        const wait = () => {
            var seatMapTrigger = document.querySelector('.tn-c-st-rsrvtn-btn');
            if (seatMapTrigger) {
                // track if seatmap was shown
                mojoService.trackEvent('feature', 'seatmap', 'show', '1');
            } else {
                iterations = iterations - 1;
                if (iterations > 0) {
                    setTimeout(wait, 500);
                }
            }
        };

        setTimeout(wait, 1000);
    }

    function getFlightInformations(component, scope, signature, data) {
        if (pageIs.BU1()) {
            const searchScope = getNestedObject(data, ['data', 'searchScope']);

            if (searchScope === 'PACKAGE') {
                const outboundFlightLegs = getNestedObject(data, ['data', 'offer', 'outboundFlight', 'legs']) || [];
                const returnFlightLegs = getNestedObject(data, ['data', 'offer', 'returnFlight', 'legs']) || [];
                const legs = outboundFlightLegs.concat(returnFlightLegs);
                const airlines = legs.map((leg) => {
                    return leg.carrier
                });

                const asr = legs.some((leg) => {
                    return leg.seatReservable
                });

                mojoService.trackData('airlines', airlines);
                mojoService.trackData('asr', asr);


                Promise.all([utagReady, pageReady]).then(() => {
                    window.tuiCottonBall.publish('tracking', 'BU1', 'Trigger utag view.', {});
                });

                waitForResultsSeatMap(10);
            }
        }
    }

    function trackSeatMapOpened(component, scope, signature, data) {
        var action = 'open';

        if (data && data.openedAutomatically) {
            action = 'auto_open'
        }

        mojoService.trackEvent('feature', 'seatmap', action, '1');
    }

    const utagReady = new Promise((resolve, reject) => {
        let counter = 0;
        const utagInterval = setInterval(() => {
            counter++;
            if (window.utag_data?.page_name !== 'IBE') {
                clearInterval(utagInterval);
                return resolve();
            }
            if (counter > 200) {
                clearInterval(utagInterval);
                reject();
            }
        }, 100);
    });

    const pageReady = new Promise((resolve) => {
        if (document.readyState === 'complete') {
            resolve();
        } else {
            document.addEventListener('readystatechange', () => {
                if (document.readyState === 'complete') {
                    resolve();
                }
            });
        }
    });

    window.tuiCottonBall.subscribe('IBE', 'BU1', 'updated seat-reservations', trackSeatmap);
    window.tuiCottonBall.subscribe('IBE', 'BU3', 'booked vacation with seat-reservations', trackSeatmap);
    window.tuiCottonBall.subscribeToAllScopes('seatmap', 'IBE checkout page rendered.', getFlightInformations);
    window.tuiCottonBall.subscribeToAllScopes('flightancillarywebcomponent', 'openSeatMap', trackSeatMapOpened);

    if (pageIs.BU3()) {
        mojoService.trackData('seat_name', 0);
        mojoService.trackData('seat_price', 0);
    }

    return 'seatmap';
}
