import { getParameterByName } from '../../elchspucke-tuicom.service';
import { listenToConnected, listenToRequestContent, updateExitIntentContents, showReisewunschPortal } from './service';
import { browserIs } from '../../util.js';

export default function (config) {

    window.tuiCottonBall.subscribe('exit-intent', '*', 'IBE search result rendered.', function (component, scope, event, data) {
        if (isOmniChannelCustomer() || !data || !data.stepNumber || browserIs.mobile()) {
            return false;
        }

        if (data.stepNumber === 3) {
            showReisewunschPortal(data);
        }

        if (data.stepNumber === 4) {
            if (!document.getElementById('exit-intent-modal-script')) {
                listenToConnected();
                listenToRequestContent(config.getAWSCdnUrl(), config.getTenant(), data);

                const exitIntentScript = Object.assign(document.createElement('script'), {
                    src: `${config.getAWSCdnUrl()}exit-intent-modal/current/index.js`,
                    id: 'exit-intent-modal-script',
                    defer: true,
                    async: true
                });

                document.body.append(exitIntentScript);

                exitIntentScript.onload = () => {
                    const exitIntentModalElement = document.createElement('exit-intent-modal');
                    exitIntentModalElement.setAttribute('data-theme', 'tuiLightCentralRegion');
                    exitIntentModalElement.setAttribute('z-index', 2000000001);
                    exitIntentModalElement.setAttribute('keyboard', '');
                    exitIntentModalElement.setAttribute('dismissable', '');
                    document.body.appendChild(exitIntentModalElement);
                };
            } else {
                updateExitIntentContents(config.getAWSCdnUrl(), config.getTenant(), data);
            }
        }
    });


    function isOmniChannelCustomer() {
        return getParameterByName('coopid') === 'o2s_tui_kudi';
    }


    return 'exit-intent';
}
