import { getPageLocale, getPageName } from '../../elchspucke-tuicom.service';
import { isActive } from '../../toggles';

export const whenDefined = (customElementName) => customElements.whenDefined(customElementName);

export const listenToConnected = () => {
    document.addEventListener('exit-intent-modal.connected', () => {
        const configurationSet = [
          { type: 'coupon', ruleSet: { expirationInHours: 24 } },
          { type: 'reisewunschportal', ruleSet: { expirationInHours: 24 } },
          { type: 'auto-offer', ruleSet: { expirationInHours: 24 } }
        ];
        const exitIntentModalElement = document.querySelector('exit-intent-modal');
        exitIntentModalElement?.setConfigurationSet(configurationSet);
    });
};

let showConfigurationSet;
let setContent;
export const listenToRequestContent = (awsCdnUrl, tenant, IBEdata) => {
    document.addEventListener('exit-intent-modal.request-content', (e) => {
        showConfigurationSet = e.detail?.showConfigurationSet;
        setContent = e.detail?.setContent;
        updateExitIntentContents(awsCdnUrl, tenant, IBEdata);
    });
}

export const updateExitIntentContents = async (awsCdnUrl, tenant, IBEdata) => {
    if (!showConfigurationSet || !setContent) {
        return;
    }

    /**
     * https://stackoverflow.com/a/41115086
     * https://bigcodenerd.org/resolving-promises-sequentially-javascript/
     *
     * We need to iterate over promises, respectively work on them in sequence
     */
    const exitIntentContents = createExitIntentContents(
        showConfigurationSet,
        setContent,
        awsCdnUrl,
        tenant,
        getPageLocale(),
        IBEdata
    );
    const promiseIterator = usePromiseIterator(exitIntentContents);
    await promiseIterator();
};

export const showReisewunschPortal = (data) => {
    if (isActive('exitIntentReisewunsch')) {
        const region = data.data.region;
        const country = data.data.country;
        const pageName = getPageName();

        if (region && country) {
            const currentCountryAndRegion = (region.name + '-' + country.name).toLowerCase();

            if (pageName !== undefined && pageName !== 'IBE' && useReiseWunsch(pageName, currentCountryAndRegion)) {
                insertReisewunschPortalScript();

                return true;
            }
        }
    }
    return false;
}

const insertReisewunschPortalScript = () => {
    window.kwizzme = {
        config: {
            baseUrl: 'https://tui.wish-service.com',
            autoOffer: true
        }
    };

    let layerScriptTag = document.createElement('script');
    layerScriptTag.type = 'text/javascript';
    layerScriptTag.async = true;
    layerScriptTag.src = 'https://tui.wish-service.com/js/layer.js';
    layerScriptTag.id = 'dt-layer';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(layerScriptTag, firstScriptTag);
}

const useReiseWunsch = (pageName, currentCountryAndRegion) => {
    if (pageName.indexOf('_FLITTERWOCHEN') > -1) {
        return true;
    }

    if (pageName.indexOf('_PT3') > -1 || pageName.indexOf('_PT4') > -1) {
        const supportedDestinations = [
            'kap verde',
            'seychellen',
            'malediven',
            'mauritius',
            'mexiko',
            'dominikanische republik',
            'dubai',
            'dhabi',
            'kaimah',
            'oman',
            'fujairah',
            'ajman',
            'quwain',
            'al ain',
            'dom. republik',
            'thailand',
            'sri lanka',
            'indonesien',
            'kuba',
            'usa',
            'jamaika',
            'barbados',
            'curacao',
            'puerto rico',
            'st.lucia',
            'tobago',
            'guadeloupe',
            'st. marteen',
            'sint maarten',
            'turks & caicos',
            'antigua & barbuda',
            'antigua und barbuda',
            'bahamas',
            'grenada',
            'afrika'
        ];

        for (let i = 0; i < supportedDestinations.length; i++) {
            if (currentCountryAndRegion.indexOf(supportedDestinations[i]) > -1) {
                return true;
            }
        }
    }

    return false;
}

const createExitIntentContents = (showConfigurationSet, setContent, awsCdnUrl, tenant, locale, IBEdata) => {
    const contentArray = [];

    for (const showConfiguration of showConfigurationSet) {
        if (showConfiguration.type === 'coupon') {
            contentArray.push(
                () =>
                    new Promise((resolve) => {
                        whenDefined('tui-coupon').then(() => {
                            // 1. Create a new coupon element
                            const couponElement = document.createElement('tui-coupon');
                            couponElement.setAttribute('data-theme', 'tui-light');
                            couponElement.setAttribute('data-legacy-font', '');
                            couponElement.setAttribute('tenant', tenant);
                            couponElement.setAttribute('locale', locale);
                            couponElement.setAttribute('class', 'coupon-exit-intent');
                            couponElement.setAttribute('variant', 'L');
                            couponElement.setAttribute('flyin', 'false');
                            couponElement.setAttribute('closable', 'false');

                            // 2. Request the needed page data to configure the coupon
                            window.tuiCottonBall.publish('tui-coupons', '*', 'Request page data.', {
                                callback: async (pageData) => {
                                    try {
                                        pageData.viewOptions = {
                                            exitIntent: true,
                                            default: false
                                        }
                                        
                                        const overwrites = getPreviewModeData();

                                        if (pageData && overwrites?.pageData?.audienceBadges?.length) {
                                            pageData.audienceBadges = overwrites.pageData.audienceBadges;
                                        }

                                        const coupon = await couponElement.applyPageData({
                                            pageData,
                                            ...(overwrites ? { overwrites } : {})
                                        });

                                        if (coupon) {
                                            if (showConfiguration.allowedToShow) {
                                                setContent({
                                                    ...showConfiguration,
                                                    htmlElement: couponElement
                                                });
                                            }

                                            return resolve(true);
                                        }
                                    } catch (e) {
                                        // eslint-disable-next-line
                                        console.error(`ExitIntent Mojo: Error applying page data to coupon`, e);
                                    } finally {
                                        resolve(false);
                                    }
                                }
                            });
                        });
                    })
            );
        }

        if (showConfiguration.type === 'reisewunschportal') {
            contentArray.push(() => new Promise((resolve) => {
                if (!showConfiguration.allowedToShow) {
                    return resolve(false);
                }

                return resolve(showReisewunschPortal(IBEdata));
            }));
        }

        if (showConfiguration.type === 'auto-offer') {
            contentArray.push(
                () =>
                    new Promise((resolve) => {
                        if (!showConfiguration.allowedToShow) {
                            return resolve(false);
                        }

                        const attendingRegionList = [
                            1259, 1174, 1212, 1154, 1289, 1187, 1231, 1182, 1213, 1142, 1139, 1140, 1262, 1315, 1306,
                            1283, 1053, 1394, 1271, 1054, 1292, 1223, 1120, 1160, 1404, 1198, 1149, 6373, 1117, 1425,
                            1406, 1240, 1386, 1532, 2729, 1081, 1065, 1108, 1537, 1560, 1057, 1083, 1242, 1250, 1298,
                            2655, 2749, 1049, 1188, 1192, 1467, 1396, 1430, 1059, 1132, 1159, 1191, 1204, 1280, 1461,
                            2451, 1051, 1058, 1064, 1072, 1087, 1088, 1089, 1090, 1091, 1095, 1107, 1115, 1118, 1123,
                            1133, 1153, 1167, 1173, 1175, 1177, 1181, 1183, 1184, 1186, 1193, 1197, 1199, 1209, 1220,
                            1222, 1249, 1260, 1268, 1270, 1281, 1296, 1297, 1324, 1325, 1427, 1435, 1436, 1463, 2751,
                            2753, 2755
                        ];

                        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
                            if (attendingRegionList.includes(ibeState.region.id)) {
                                const autoOfferScript = Object.assign(document.createElement('script'), {
                                    src: `${awsCdnUrl}auto-offer/v1/tui-auto-offer.js`,
                                    id: 'auto-offer',
                                    defer: true,
                                    async: true
                                });

                                autoOfferScript.onload = () => {
                                    const autoOfferElement = document.createElement('tui-auto-offer');
                                    autoOfferElement.setAttribute('tenant', tenant);
                                    autoOfferElement.setAttribute('locale', locale);
                                    setContent({ ...showConfiguration, htmlElement: autoOfferElement });
                                    return resolve(true);
                                };

                                document.body.append(autoOfferScript);
                            } else {
                                resolve(false);
                            }
                        });
                    })
            );
        }
    }

    return contentArray;
};

const usePromiseIterator = (contentArray) => {
    if (!Array.isArray(contentArray)) {
        throw new Error('contentArray needs to be an array');
    }

    function* generator() {
        yield* contentArray;
    }

    const iterator = generator();
    let next = iterator.next();

    return async () => {
        let successful = false;
        while (!next.done) {
            if (typeof next.value !== 'function') {
                throw new Error('Iteration element needs to be a function');
            }

            if (await next.value()) {
                successful = true;
                break;
            }
            next = iterator.next();
        }

        return successful;
    };
};

function getPreviewModeData() {
    const data = sessionStorage.getItem('tui-coupon_preview-mode_data');

    return data ? JSON.parse(data) : null;
}
