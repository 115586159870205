import * as elchspuckeTuicomService from "../../elchspucke-tuicom.service";
import FacultativeTripsService from "../../facultative-trips/1.1.0/service";
import { trackEvent } from "../../elchspucke-tuicom.service";

export default function () {

    if (window.tuiCottonBall) {
        window.tuiCottonBall.cuddleMeIf('facultative-trips', '*', 'IBE checkout confirmation page rendered.', (component, scope, signature, payload) => {
            let facultativeTripNode = document.querySelector('.facultative-trips-wrapper');
            if (facultativeTripNode) {
                elchspuckeTuicomService.removeNode(facultativeTripNode);
            }

            const { data = {} } = payload;
            const { hotel = {}, region = {}, offer = {} } = data;
            const { destination = {} } = offer;            

            if (hotel.giataId && destination && destination.airportCode) {
                if (region.name) {
                    FacultativeTripsService.insertFacultativeTrips(hotel.giataId, region.name, destination.airportCode);
                } else {
                    FacultativeTripsService.insertFacultativeTrips(hotel.giataId, 'no region', destination.airportCode);
                }
            }
        });

        // eslint-disable-next-line no-unused-vars
        window.tuiCottonBall.cuddleMeIf('facultative-trips', '*', 'Fetching activity data', (component, scope, signature, payload) => {
            // tracking musement with region
            document.querySelectorAll('tui-musement-offers').forEach(musement => {
                musement.addEventListener("tui-musement-offers.clickedButton", (event) => {
                    const { name } = event.detail;
                    trackEvent('feature', 'musement', 'click', name || 'button clicked');
                });
            });
        });
    }

    return 'facultative-trips';
}


