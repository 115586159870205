import * as SearchHistoryService from './service.js';
import { getCookie } from "../../elchspucke-tuicom.service.js";

export default function(config) {
    if (window.tuiCottonBall) {
        window.tuiCottonBall.broadcast('login', 'Register my component.', { name: 'search-history' });
        
        window.tuiCottonBall.subscribe('search-history', '*', 'IBE search result rendered.', function (a,b,c,data) {
            const cookieValue = getCookie('_vis_opt_exp_18309_combi');
            if (data.stepNumber === 4 && cookieValue === '2') {
                SearchHistoryService.injectLastSeen(config)
                window.tuiCottonBall.broadcast("cotton-ball", "New TUI components.")
            }
            SearchHistoryService.rememberHotel(data);
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'Blackbird rendered.', function (a,b,c,data) {
            SearchHistoryService.rememberHotel(data);
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'Get current user.', function () {
            window.tuiCottonBall.broadcast('login', 'Get login state.', {});
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'The current users login state.', function (a,b,c,data) {
            window.tuiCottonBall.broadcast('search-history', 'Current user token.', data);
            SearchHistoryService.addHotel(config.getAWSApiUrl(), config.getTenant(), data.token);
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'Let the user log in.', function () {
            window.tuiCottonBall.publish('login', 'login', 'Open login overlay.', {});
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'History card was clicked.', function (a, b, c, data) {
            SearchHistoryService.openHotelDetailPage(window.location.origin, data);
            /*
            const dataObject = {
                callback: (a,b,c,url) => {
                    window.open(url, '_blank');
                },
                destination: { giataId: data.giataId, name: data.hotelName },
                filters: SearchHistoryService.getFilterData(data.searchParameter)
            };
            window.tuiCottonBall.broadcast('IBE', 'generate URL',dataObject);
            */
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'Start a new search history.', function () {
            window.location.href = window.location.origin;
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'Call me back with my local search history.', function (component, scope, event, callback) {
            SearchHistoryService.getHotelsFromLocalStorage(callback);
        });

        window.tuiCottonBall.subscribe('search-history', '*', 'Last seen rendered.', function (a, b, c, data) {
            SearchHistoryService.injectCrystalCards(data);
        });
    }

    return 'search-history';
}
