import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';
export default function () {
    document.addEventListener('tui-image-gallery.cinema-view-closed', (e) => {
        elchspuckeTuicomService.trackEvent('feature', `gallery_mfe-${e.detail.variant}`, 'cinemaViewClosed', 1);
    });
    document.addEventListener('tui-image-gallery.cinema-view-opened', (e) => {
        elchspuckeTuicomService.trackEvent('feature', `gallery_mfe-${e.detail.variant}`, 'cinemaViewOpened', 1);
    });
    document.addEventListener('tui-image-gallery.category-selected', (e) => {
        elchspuckeTuicomService.trackEvent(
            'feature',
            `gallery_mfe-${e.detail.variant}`,
            'categorySelected',
            e.detail.label
        );
    });
    document.addEventListener('tui-image-gallery.cinema-cta-clicked', (e) => {
        elchspuckeTuicomService.trackEvent('feature', `gallery_mfe-${e.detail.variant}`, 'cinemaCtaClicked', 1);
    });
    
    document.addEventListener('tui-image-gallery.thumbnail-selected', (e) => {
        elchspuckeTuicomService.trackEvent(
            'feature',
            `gallery_mfe-${e.detail.variant}`,
            'thumbnailSelected',
            `${e.detail.mediumType}-${e.detail.title}`
        );
    });
}
