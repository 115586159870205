import * as ElchspuckeService from '../../elchspucke-tuicom.service';

export default function() {
    window.tuiCottonBall.cuddleMeIf('filter', '*', 'Track filter changes.', function (component, scope, event, data) {
        if (data && data.filterName && data.value !== undefined) {
            ElchspuckeService.trackEvent('feature', `deals_filter_${data.filterName}`, 'select', data.value);
        }
    });

    return 'filter';
}
