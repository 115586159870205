import { trackData, trackEvent } from '../../elchspucke-tuicom.service';
import { trackFlight, trackFlightPrice, trackFlightString, getTrackingSeats, joinTrackingData } from './service';

export default () => {
    // Funnel tracking
    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'tfm initialized.', () => {
        trackData('page_module', 'flug_nbso', false, false);
    });

    // tracking changed page_name
    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'tfm page navigation.', (c, s, e, data) => {
        // make sure page_module is flug_nbso
        trackData('page_module', 'flug_nbso', false, false);
        if (data) {
            switch (data.page) {
                case 'search-start': // tfm search opened without predefined search params
                    trackData('page_name', 'flug_home', true, true);
                    break;
                case 'search-direct': // tfm search opened with predefined search params
                case 'search-results': // tfm search results due to user search
                    trackData('page_name', 'flug_pt3', true, true);
                    break;
                case 'booking-initial': // tfm booking opened without predefined booking params
                case 'booking-direct': // tfm booking opened with predefined booking params
                    trackData('page_name', 'flug_bu1', true, true); // send with data: page_name: flug_bu1
                    break;
                case 'booked':
                    // booking was confirmed
                    trackData('page_name', 'flug_bu3', true, true);
                    break;
            }
        }
    });

    /**
     * Track if Link is clicked that triggers cottonBall Event 'a link has been clicked.'
     * Used in:
     * NBSO-2619: track click events for seatmap
     * track click for vers4u (Insurance partner)
     */
    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'a link has been clicked.', (c, s, e, data) => {

        if (data.action) trackEvent('feature', 'link', data.action, data.name);
        else trackEvent('feature', 'link', 'link clicked', data.name);
    });

    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'a button has been clicked.', (c, s, e, data) => {
        if (data) trackEvent('feature', 'link', 'button clicked', data.name);
    });

    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'an error occured.', (c, s, e, data) => {
        if (data) trackEvent('feature', data.category, 'error occured', data.value);
    });

    // Triggeren on BU1, when land on page.
    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'PriceUpdateSelect.', (c, s, e, data) => {
        trackFlight(data);
        trackData('nbso_price_update', data.priceDiffPercentage);
        trackEvent('feature', 'event', 'flightPriceUpdateSelect', 'nbso_price_update=' + data.priceDiffPercentage);
    });

    // Triggered on BU1, after click on "Kostenpflichtig buchen" and when price changed during booking process
    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'PriceUpdateBooking.', (c, s, e, data) => {
        trackFlight(data);
        trackData('nbso_price_update', data.priceDiffPercentage);
        trackEvent('feature', 'event', 'flightPriceUpdateBooking', 'nbso_price_update=' + data.priceDiffPercentage);
    });

    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'FlightSearch.', (c, s, e, data) => {
        if (!data) return;
        trackFlight(data);
        trackEvent('feature', 'event', 'flightSearch', trackFlightString(data));
    });

    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'FlightSummary.', (c, s, e, data) => {
        if (!data) return;
        trackFlight(data);
        if (data.resultsCount) {
            trackData('flight_results_count', data.resultsCount);
            trackEvent('feature', 'event', 'results_count', data.resultsCount);
        }
    });

    // TODO: need more klärung
    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'FlightFetch.', (c, s, e, data) => {
        if (!data) return;

        trackData('startDate', data.startDate, false, false);
        trackData('returnDate', data.returnDate, false, false);
        trackData('number_of_travellers_adults', data.adultCount, false, false);
        trackData('number_of_travellers_children', data.childCount, false, false);
        trackData('number_of_travellers_infants', data.infantCount, false, false);
        // first airport set as departure_airport
        const departureAirport = data.origins && data.origins.length > 0 ? data.origins[0] : '';
        trackData('departure_airport', departureAirport, false, false);
        // last airport set as arrival_airport
        const arrivalAirport =
            data.destinations && data.destinations.length > 0 ? data.destinations[data.destinations.length - 1] : '';
        trackData('arrival_airport', arrivalAirport, false, false);
        // first airline set as departure_airline
        const departureAirline =
            data.carrierCodesStart && data.carrierCodesStart.length > 0 ? data.carrierCodesStart[0] : '';
        trackData('departure_airline', departureAirline, false, false);
        // last airline set as return_airline
        const returnAirline =
            data.carrierCodesReturn && data.carrierCodesReturn.length > 0
                ? data.carrierCodesReturn[data.carrierCodesReturn.length - 1]
                : '';
        trackData('return_airline', returnAirline, false, false);
        // first flightnumber from flightNumbersStart set as flight_number with the carrier codeof the first departure airline
        const flightNumber =
            data.carrierCodesStart &&
                data.carrierCodesStart.length > 0 &&
                data.flightNumbersStart &&
                data.flightNumbersStart.length > 0
                ? data.carrierCodesStart[0] + data.flightNumbersStart[0]
                : '';
        trackData('flight_number', flightNumber, false, false);
        trackData('product_totalprice', data.bruttoPrice, false, false);
        trackData('product_price_adult_per_pax', data.bruttoPriceAdult, false, false);
        trackData('product_type', 'flight', false, false);

        let utagViewFlightData = trackFlight(data);
        let utagViewFlightPriceData = trackFlightPrice(data, false);

        let utagViewFlightFetchData = Object.assign(utagViewFlightData, utagViewFlightPriceData);

        if (Object.keys(utagViewFlightFetchData).length > 0) window.utag.view(utagViewFlightFetchData);

        trackEvent('feature', 'event', 'fetch', trackFlightString(data));
    });

    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'FlightSelect.', (c, s, e, data) => {
        if (!data) {
            return;
        }
        trackFlight(data);
        trackEvent('feature', 'event', 'FlightSelect', trackFlightString(data));
        // let utagViewFlightData = trackFlight(data, false);
        // let utagViewFlightPriceData = trackFlightPrice(data, false);
        // let utagViewFlightSelectData = Object.assign(utagViewFlightData, utagViewFlightPriceData);
        // if( Object.keys(utagViewFlightSelectData).length > 0) {
        //     window.utag.view(utagViewFlightSelectData);
        // }
        // trackEvent('feature', 'event', 'select', trackFlightString(data));
    });

    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'FlightBooked.', (c, s, e, data) => {
        if (!data) {
            return;
        }

        trackData('startDate', data.startDate, false, false);
        trackData('returnDate', data.returnDate, false, false);
        trackData('number_of_travellers_adults', data.adultCount, false, false);
        trackData('number_of_travellers_children', data.childCount, false, false);
        trackData('number_of_travellers_infants', data.infantCount, false, false);
        // first airport set as departure_airport
        const departureAirport = data.origins && data.origins.length > 0 ? data.origins[0] : '';
        trackData('departure_airport', departureAirport, false, false);
        // last airport set as arrival_airport
        const arrivalAirport =
            data.destinations && data.destinations.length > 0 ? data.destinations[data.destinations.length - 1] : '';
        trackData('arrival_airport', arrivalAirport, false, false);
        // first airline set as departure_airline
        const departureAirline =
            data.carrierCodesStart && data.carrierCodesStart.length > 0 ? data.carrierCodesStart[0] : '';
        trackData('departure_airline', departureAirline, false, false);
        // last airline set as return_airline
        const returnAirline =
            data.carrierCodesReturn && data.carrierCodesReturn.length > 0
                ? data.carrierCodesReturn[data.carrierCodesReturn.length - 1] : '';
        trackData('return_airline', returnAirline, false, false);
        // first flightnumber from flightNumbersStart set as flight_number with the carrier codeof the first departure airline
        const flightNumber =
            data.carrierCodesStart &&
                data.carrierCodesStart.length > 0 &&
                data.flightNumbersStart &&
                data.flightNumbersStart.length > 0
                ? data.carrierCodesStart[0] + data.flightNumbersStart[0] : '';
        trackData('flight_number', flightNumber, false, false);
        trackData('product_totalprice', data.bruttoPrice, false, false);
        trackData('product_price_adult_per_pax', data.bruttoPriceAdult, false, false);

        if (data.journeyType) {
            trackData('product_type', data.journeyType);
        }

        if (data.tfaData) {
            // NBSO-2618: if seat data available, concat with the default flight products like flight or infant
            const trackSeats = getTrackingSeats(data.tfaData);
            data.product_name = data.product_name.concat(trackSeats.product_name);
            data.product_code = data.product_code.concat(trackSeats.product_code);
            data.product_price = data.product_price.concat(trackSeats.product_price);
            data.product_quantity = data.product_quantity.concat(trackSeats.product_quantity);
            data.product_type = data.product_type.concat(trackSeats.product_type);
        }

        // if array send a commasepared list to tealium
        trackData('product_name', joinTrackingData(data.product_name), false, false);
        trackData('product_code', joinTrackingData(data.product_code), false, false);
        trackData('product_price', joinTrackingData(data.product_price), false, false);
        trackData('product_quantity', joinTrackingData(data.product_quantity), false, false);
        trackData('product_type', joinTrackingData(data.product_type), false, false);

        // set tfaData to null, so not the whole tfa-seat-object is tracked
        data.tfaData = null;
        trackFlight(data);
        trackFlightPrice(data);
        // let utagViewFlightData = trackFlight(data, 'flug_bu3');
        // let utagViewFlightPriceData = trackFlightPrice(data, false, 'flug_bu3');

        // let utagViewFlightBookedData = Object.assign(utagViewFlightData, utagViewFlightPriceData);

        // if (Object.keys(utagViewFlightBookedData).length > 0) {
        //   window.utag.view(utagViewFlightBookedData);
        // }
        trackData('page_name', 'flug_bu3', true, true);
    });
}
