import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';
import * as util from '../../util.js';

function hotelCardsEventScope() {
    return {
        historyCards: 'historyCards',
        HC_CB_topHotels_Home: 'HC_CB_topHotels_Home',
        HC_CB_topHotels_SINGLE_Home: 'HC_CB_topHotels_SINGLE_Home',
        HC_CB_topHotels_COUPLE_Home: 'HC_CB_topHotels_COUPLE_Home',
        HC_CB_topHotels_FAMILY_Home: 'HC_CB_topHotels_FAMILY_Home',
    };
}

function buildCampaignData(data, campaigns) {
    var result = {};
    result.instanceId = data.instanceId;
    result.campaignMembers = [];
    var giataIds = data.hotels.map(hotel => hotel.giataId);
    var cardsLanguage = elchspuckeTuicomService.getPageLanguage();

    campaigns.sort((a,b) => (a.campaignConfiguration.priority > b.campaignConfiguration.priority) ? 1 : ((b.campaignConfiguration.priority > a.campaignConfiguration.priority) ? -1 : 0));

    campaigns.forEach(function (campaign) {
        var campaignConfiguration = campaign.campaignConfiguration;
        if (campaignConfiguration && new Date(data.searchParameter.departureDate) < new Date(campaignConfiguration.offersSpecification.endDate) && new Date(data.searchParameter.returnDate) > new Date(campaignConfiguration.offersSpecification.startDate)) {
            if (giataIds !== null && Array.isArray(giataIds)) {
                var campaignTranslation = campaignConfiguration.languages.find(function (translation) {
                    return translation.shortName === cardsLanguage;
                });
                giataIds.forEach(function (giataId) {
                    var campaignData = campaignConfiguration.hotels.find(function (hotel) {
                        return hotel.giataId === giataId && Array.isArray(hotel.searchScopes) && hotel.searchScopes.indexOf(data.searchParameter.searchScope) >= 0;
                    });
                    var layout = campaignConfiguration.layout || {};
                    if (campaignData && result.campaignMembers.map(member => member.giata).indexOf(giataId) < 0) {
                        result.campaignMembers.push({
                            giata: campaignData.giataId,
                            campaignIcon: layout.alternativeCampaignIcon,
                            campaignLabel: campaignData.tag? campaignTranslation.translations.tagSpecific[campaignData.tag].label:campaignTranslation.translations.label,
                            campaignDescription: campaignTranslation.translations.toolTip,
                            campaignStyle: 'primary',
                            campaignBackground: layout.background,
                            campaignFontColor: layout.text
                        });
                    }
                });
            }
        }
    });
    window.tuiCottonBall.broadcast('hotel-cards', 'Here is your campaign data.', result);
}

// we need to keep some values for tracking, for example:
// coopid=AFF_TUI_TRIPADVISOR&utm_source=tripadvisor&utm_medium=metasearch&utm_campaign=hotelonly&utm_content=28019&utm_term=tripadvisor
var addAffiliateParameter = function () {
    var affiliateParam = '';
    affiliateParam += checkParameter('coopid');
    affiliateParam += checkParameter('utm_source');
    affiliateParam += checkParameter('utm_medium');
    affiliateParam += checkParameter('utm_campaign');
    affiliateParam += checkParameter('utm_content');
    affiliateParam += checkParameter('utm_term');
    affiliateParam += checkParameter('TAprice');
    return affiliateParam;
};

var checkParameter = function (name) {
    var value = elchspuckeTuicomService.getParameterByName(name);
    if (value) {
        return '&' + name + '=' + value;
    }
    return '';
};

var getChildAges = function(ages) {

    if (!ages || typeof ages !== 'string' || ages.length < 1) {
        return [];
    }

    let childAges = [];
    const childAgesArray = ages.split(';');

    for (let i=0; i < childAgesArray.length; i++) {
        childAges.push(parseInt(childAgesArray[i], 10));
    }
    return childAges;
};

var getChildren = function(children) {
    let travellers = [];
    let childrenTravellers;

    if (!children || typeof children !== 'string') {
        return travellers;
    }

    if (children.length === 0) {
        return travellers.push({children:[]});
    }

    if (children.length > 0) {

        childrenTravellers = children.split(',');

        for (let i=0; i < childrenTravellers.length; i++) {
            travellers.push({children: getChildAges(childrenTravellers[i])});
        }
    }
    return travellers;
};

var mapAdultsAndChildsToIBETravellers = (adults, children) => {

    let travellers = [];
    let adultsTravellers;
    let childrenTravellers = getChildren(children);

    if (!adults) {
        return travellers;
    }

    adultsTravellers = adults.split(',');

    for (let i=0; i < adultsTravellers.length; i++) {
        travellers.push({adults: parseInt(adultsTravellers[i], 10), children: childrenTravellers[i] ? childrenTravellers[i].children : []});
    }

    return travellers;
};

var mapHotelCardsParametersToIBEFilter = function(parameters) {

    let filters = {};

    if (parameters.searchScope) {
        filters.searchScope = parameters.searchScope.toUpperCase();
    }

    if (parameters.departureDate) {
        filters.startDate = new Date(parameters.departureDate);
    }

    if (parameters.returnDate) {
        filters.endDate = new Date(parameters.returnDate);
    }

    if (parameters.durations) {
        filters.duration = parameters.durations;
    }

    if (parameters.boardTypes) {
        filters.boardTypes = parameters.boardTypes;
    }

    if (parameters.roomTypes) {
        filters.roomTypes = parameters.roomTypes;
    }

    if (parameters.departureAirportCodes) {
        filters.departureAirports = parameters.departureAirportCodes;
    }

    if (parameters.adults) {
        filters.travellers = mapAdultsAndChildsToIBETravellers(parameters.adults, parameters.childs);
    }

    return filters;
};

var getTUICOM_PTH_URL = function (plattformUrl, component, scope, event, data) {

    if (!data || !data.hotel || !data.searchParameter) {
        return false;
    }

    let toBeSetFilters = mapHotelCardsParametersToIBEFilter(data.searchParameter);

    let destinationObj = {
        giataId: data.hotel.giataId,
        name: data.hotel.displayName
    };

    const triggerClick = function(url) {

        if (data.props && data.props.openInNewTab === 'true') {
            window.open(url, '_blank');
        } else {
            window.location.href = url;
        }
    };

    window.tuiCottonBall.broadcast('IBE', 'generate URL',
        {
            filters: toBeSetFilters,
            destination: destinationObj,
            callback: function (component, scope, event, url) {
                let targetUrl = util.cleanStringFromTrailingChar(plattformUrl, '/') + url;
                targetUrl += addAffiliateParameter();

                triggerClick(targetUrl);
            }
        }
    );
};

var buildUrlsCallback = function(plattformUrl, component, scope, event, data) {

    if (!data || !data.hotels || !data.searchParameter) {
        return false;
    }

    let toBeSetFilters = mapHotelCardsParametersToIBEFilter(data.searchParameter);

    var builtURLs = [];

    for (var i = 0; i < data.hotels.length; i++) {

        let destinationObj = {
            giataId: data.hotels[i].giataId,
            name: data.hotels[i].displayName
        };

        window.tuiCottonBall.broadcast('IBE', 'generate URL',
            {
                filters: toBeSetFilters,
                destination: destinationObj,
                callback: function (component, scope, event, url) {
                    let targetUrl = util.cleanStringFromTrailingChar(plattformUrl, '/') + url;
                    targetUrl += addAffiliateParameter();
                    builtURLs.push({giata: destinationObj.giataId, url: targetUrl });
                    window.tuiCottonBall.broadcast('hotel-cards', 'Get link targets.', {iid: data.instanceId, values: builtURLs});
                }
            }
        );
    }
};

const getCardsPriceType = (data) => {
    return data.showTotalPrice ? 'TOTAL' : 'PER_PERSON';
}

const updateCards = (data) => {

    if (data) {
        const priceType = getCardsPriceType(data);
        const hotelCardAttributes = {
            priceType
        };
        window.tuiCottonBall.broadcast('hotel-cards', 'Load cards configuration.', hotelCardAttributes);
    }
}

export {
    buildUrlsCallback,
    hotelCardsEventScope,
    buildCampaignData,
    getTUICOM_PTH_URL,
    updateCards
};
