import * as mojoService from '../../elchspucke-tuicom.service';
import * as ibeMappingService from '../../ibe-mapping.service';
import * as roomsPanelService from './service';

/**
 * Start the rooms panel mojo on pth4
 * @param  {string} language    the language to start the panel with (like de_DE, de_CH, …)
 * @param  {string} priceFormat the price format to start the panel with (like de, ch, …)
 * @return {string}             the mojo name for event registration
 */
export default function (config) {
    const language = config.getLanguage();
    const priceFormat = config.getCurrency();

    const eventScope = 'pth4';
    const panelId = 'tui-rooms-panel';

    // 0. Listen to A/B-Test
    let roomsPanelToggle = true;
    let roomsPanelElement;
    let showPrice;
    let IBESearchResultData;

    window.tuiCottonBall.cuddleMeIf(
        'rooms-panel-mojo',
        '*',
        'Switch rooms panel on.',
        (c,s,e,eventData) => {
            roomsPanelToggle = true;
            if (eventData) {
                showPrice = eventData.showPrice;
            }
            injectRoomsPanel(IBESearchResultData);
        }
    );

    // 1. Render panel after IBE finished rendering / set new search params
    window.tuiCottonBall.cuddleMeIf(
        'rooms-panel-mojo',
        '*',
        'IBE search result rendered.',
        (c,s,e,eventData) => {
            IBESearchResultData = eventData;

            // listen to A/B-Test
            // remove when not longer A/B-Tested
            if (!roomsPanelToggle) {
                return false;
            }

            injectRoomsPanel(IBESearchResultData);
        }
    );

    function injectRoomsPanel(eventData) {

        if (eventData && eventData.stepNumber === 4) {
            const panelElement = document.getElementById(panelId);
            // inject if not already present
            if (!panelElement) {
                // default to de_DE if no language was configured
                roomsPanelElement = roomsPanelService.createRoomsPanelDiv(
                    language ? language : 'de_DE',
                    priceFormat ? priceFormat : 'de',
                    panelId,
                    eventScope);
                const injectionSibbling = document.querySelector(roomsPanelService.getPanelInjectionSelector());
                if (injectionSibbling) {
                    mojoService.insertAsFirstChildIfExists(roomsPanelElement, injectionSibbling);
                    // in case the panel code was already load elsewhere, we need to triger bootstrapping again
                    window.tuiCottonBall.broadcast('rooms-panel', 'hit me baby one more time.', {});
                    // new cotton ball scan, panel starts at found in dom listener
                    window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
                }
            } else {
                // render panel directly
                initRoomsPanel(panelElement, showPrice);
            }
        }
    }

    // 2. catch bootstrapping finished event to start the given rooms panel
    window.tuiCottonBall.cuddleMeIf(
        'rooms-panel',
        eventScope,
        'was found in the DOM while bootstrapping.',
        (c,s,e,eventData) => {
            initRoomsPanel(eventData.element, showPrice);
        }
    );

    /**
     * Initialises the panel with the search data retreived from the IBE
     */
    function initRoomsPanel(element, showPrice) {

        // retreive ibe search params
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
            if (!ibeState) {
                return false;
            }

            const commonSearchModel = ibeMappingService.mapIbeSearchParamsToPeakwork(ibeState);
            const priceType = roomsPanelService.extractPriceType(ibeState);

            if (!roomsPanelService.searchModelIsProcessable(commonSearchModel) || !priceType) {
                return false;
            }

            // ignite
            window.tuiCottonBall.pleaseTakeCareOf(
                'rooms-panel',
                '*',
                'render element.',
                roomsPanelService.constructIgnitionData(commonSearchModel, priceType, element, showPrice)
            );
        });
    }

    // 3. catch the rooms panels selection event to set a filter in the IBE
    window.tuiCottonBall.cuddleMeIf(
        'rooms-panel',
        eventScope,
        'I have chosen you, my favorite room.',
        (c,s,e,eventData) => {
            if (eventData && eventData.selectedRooms) {
                let roomTypeOpCode = '';
                if (eventData.selectedRooms.length > 0) {
                    roomTypeOpCode = eventData.selectedRooms[0].roomCode;
                }
                mojoService.trackEvent('feature', 'roompanel', 'select', roomTypeOpCode);
                window.tuiCottonBall.broadcast('IBE', 'New filters were set.', {roomTypeOpCodes: [roomTypeOpCode], page: 'ibe.offers.offer'});
            }
        }
    );

    return 'rooms-panel-mojo';
}
