import {trackData, trackEvent} from '../../elchspucke-tuicom.service';


export default function () {

    const bannerScope = 'BU3-hotel';
    let flightFoundBannerTrackingString;

    window.tuiCottonBall.cuddleMeIf('flightbanner', bannerScope, 'banner initialized', function() {
        trackEvent('flight banner', 'bu3', 'banner started');
    });

    window.tuiCottonBall.cuddleMeIf('flightbanner', bannerScope, 'button clicked', () => {
        if (flightFoundBannerTrackingString) {
            trackEvent('flight banner', 'bu3', 'banner clicked');
        } else {
            trackEvent('flight banner', 'bu3', 'banner without flight clicked');
        }
    });

    window.tuiCottonBall.cuddleMeIf('flightbanner', bannerScope, 'banner finished with no result', () => {
        trackEvent('flight banner', 'bu3', 'banner shown without flight');
    });

    window.tuiCottonBall.cuddleMeIf('flightbanner', bannerScope, 'banner finished with result', (c, s, e, data) => {
        if (data) {
            flightFoundBannerTrackingString = 'depAirport=' + data.originAirport
            + ',price=' + data.searchButtonShown
            + ',sID=' + data.nbsoSessionId;
            trackData('nbso_flight_banner', flightFoundBannerTrackingString);
        }
        trackEvent('flight banner', 'bu3', 'banner shown with flight');
    });
}
