import { trackData } from "../../elchspucke-tuicom.service";
/**
 * generates a flightString to be able to hand over every flight relevant information to one tracking variable
 * @param data {object}
 * @returns {string|boolean}
 */
export const trackFlightString = (data) => {

    if (!data || data.length < 1 ||
        typeof data === 'boolean' || typeof data === 'number' ||
        (typeof data === 'object' && Object.entries(data).length === 0)) {

        return false;
    }

    /**
     * special handling the date values to be transformed to a string
     * @param date
     * @param firstStringSet {boolean}
     * @returns {{firstStringSet: string|boolean}}
     */
    const setDateString = (date, firstStringSet) => {
        let returnObject = {
            firstStringSet
        };

        if (firstStringSet) {
            returnObject.dateString = '_' + date;
        } else {
            returnObject.dateString = date;
            returnObject.firtStringSet = true;
        }

        return returnObject;
    };

    /**
     * converting arrays to a string separated by an underscore
     * @param arrayContents
     * @param firstStringSet
     * @returns {{firstStringSet: string|boolean}}
     */
    const setStringFromArray = (arrayContents, firstStringSet) => {
        if (!arrayContents ||
            typeof arrayContents !== 'object' ||
            arrayContents.length <= 0 ||
            Array.isArray(arrayContents) === false) {

            return {
                contentString: '',
                firstStringSet
            };
        }

        let returnObject = {
            firstStringSet
        };

        arrayContents.forEach((content, index) => {
            if (index <= 0) {
                if (firstStringSet) {
                    returnObject.contentString = '_' + content;
                } else {
                    returnObject.contentString = content;
                    returnObject.firstStringSet = true;
                }
            } else {
                returnObject.contentString += '_' + content;
            }
        });

        return returnObject;
    };

    let flightString = '';
    let firstStringSet = false;

    // set start date
    if (data.startDate) {
        const startDate = setDateString(data.startDate, firstStringSet);
        flightString += startDate.dateString;
        firstStringSet = startDate.firtStringSet;
    }

    // set carrierCodes for outgoing flights
    if (data.carrierCodesStart && typeof data.carrierCodesStart === 'object') {
        const carrierCode = setStringFromArray(data.carrierCodesStart, firstStringSet);
        flightString += carrierCode.contentString;
        firstStringSet = carrierCode.firstStringSet;
    }

    // set origins string for outgoing flights
    // set destinations string for outgoing flights
    // 2020-08-15_HAJ-PMI_2020-08-23_PMI-HAJ (the first half)
    flightString += data.origins ? '_' + data.origins : '';
    flightString += data.destinations ? '-' + data.destinations : '';

    // set return date
    if (data.returnDate) {
        const returnDate = setDateString(data.returnDate, firstStringSet);
        flightString += returnDate.dateString;
        firstStringSet = returnDate.firstStringSet;
    }

    // set carrier codes for return flights (not available when only search, not selecting/booking)
    if (data.carrierCodesReturn && typeof data.carrierCodesReturn === 'object') {
        const carrierCode = setStringFromArray(data.carrierCodesReturn, firstStringSet);
        flightString += carrierCode.contentString;
        firstStringSet = carrierCode.firstStringSet;
    }

    // set destinations string for return flights
    // set origins string for return flights
    // 2020-08-15_HAJ-PMI_2020-08-23_PMI-HAJ (the second half, PMI-HAJ)
    flightString += data.destinations ? '_' + data.destinations : '';
    flightString += data.origins ? '-' + data.origins : '';

    return flightString;
};

/**
 * transforms a list to a string, with strings separated by semicolons
 * @param givenArray {Array}
 * @returns {string|boolean}
 */
export const transformArrayToString = (givenArray) => {
    if (!givenArray ||
        typeof givenArray !== 'object' ||
        givenArray.length <= 0 ||
        Array.isArray(givenArray) === false) {
        return false;
    }

    let arrayAsString = '';

    givenArray.forEach((value, index) => {
        let delimiter = (index === 0) ? '' : ';';
        arrayAsString += delimiter + value;
    });

    return arrayAsString;
};

/**
 * clustering the given value in groups
 * @param percentageValue {string|number}
 * @returns {string|boolean}
 */

export const clusterPercentages = (percentageValue) => {
    if ((typeof percentageValue !== 'string' && typeof percentageValue !== 'number') ||
        percentageValue.length === 0) {
        return false;
    }

    if (typeof percentageValue === 'string') {
        percentageValue = parseInt(percentageValue);
    }

    let returnValue;
    if (percentageValue === 0) {
        returnValue = '0';
    } else if (percentageValue <= 10) {
        returnValue = '1-10';
    } else if (percentageValue <= 30) {
        returnValue = '11-30';
    } else if (percentageValue <= 50) {
        returnValue = '31-50';
    } else if (percentageValue <= 70) {
        returnValue = '51-70';
    } else if (percentageValue <= 90) {
        returnValue = '71-90';
    } else if (percentageValue <= 100) {
        returnValue = '91-100';
    } else if (percentageValue > 100) {
        returnValue = '>100';
    }

    return returnValue;
};

/**
 * generates a valid URL string from data object
 * @param data {object}
 * @returns {string|boolean}
 */

export const generateUrlParamsString = (data) => {
    if (!data || typeof data !== 'object' || Object.keys(data).length <= 0) {
        return false;
    }

    let parameterString = '';

    Object.keys(data).forEach((key, index) => {
        let delimeter = (index === 0) ? '' : '&';

        if (typeof data[key] === 'object') {
            parameterString += delimeter + key + '=' + transformArrayToString(data[key]);
        } else {
            if (key === 'priceChangePercentage') {
                parameterString += delimeter + key + '=' + clusterPercentages(data[key]);
            } else {
                parameterString += delimeter + key + '=' + data[key];
            }
        }
    });

    return parameterString;
};

/**
 * Set up utag data with Flight informations.
 * @param {*} data 
 */
export const trackFlight = (data) => {

    if (data.origins) trackData('departure_airport', data.origins);

    if (data.destinations) trackData('arrival_airport', data.destinations);

    if (data.startDate) trackData('date_range_departure_searched', data.startDate);

    if (data.returnDate) trackData('date_range_return_searched', data.returnDate);

    if (data.product_id) trackData('product_id', data.product_id);

    if (data.order_id) trackData('order_id', data.order_id);

    if (data.product_type) trackData('product_type', data.product_type);

    /*if (data.flex_check) NOT YET IMPLEMENTED*/
    trackData('flex_check', 'N');

    if (data.maturity) trackData('days_to_departure', data.maturity);

    if (data.duration) trackData("days_travel_duration_chosen", data.duration);

    trackData('product_brand', 'TUI');

    const adultCount = data.adultCount ? data.adultCount : 0
    trackData('number_of_travellers_adults_chosen', adultCount);

    const childCount = data.childCount ? data.childCount : 0;
    if (childCount > 0) trackData('number_of_travellers_children_chosen', childCount);

    const infantCount = data.infantCount ? data.infantCount : 0;
    if (infantCount > 0) trackData('number_of_travellers_infants_chosen', infantCount)

    const productQuantity = adultCount + childCount + infantCount;

    if (data.product_quantity) trackData('product_quantity', data.product_quantity);
    else if (productQuantity >= 0) trackData('product_quantity', productQuantity.toString());
    else trackData('product_quantity', '1');

    trackData('number_of_travellers_total_chosen', productQuantity);
};

/**
 * Set up utag data for price tracking
 * @param {*} data 
 */
export const trackFlightPrice = (data) => {
    let utagViewData = {};

    if (data.bruttoPriceChild) trackData('product_price_children', data.bruttoPriceChild);

    if (data.bruttoPriceInfant) trackData('product_price_infants', data.bruttoPriceInfant);


    if (data.bruttoPriceAdult) {
        trackData('product_price_adults', data.bruttoPriceAdult);
        utagViewData.product_price_adults = data.bruttoPriceAdult;
    }

    if (data.product_price) trackData('product_price', data.product_price);

    if (data.bruttoPrice) trackData('product_totalprice', data.bruttoPrice);
    else if (data.product_totalprice) trackData('product_totalprice', data.product_totalprice);
};

/**
 * prepare all data to a string for the Pricupdate Events
 * @param data object with given page data
 */
export const trackPriceUpdates = (data) => {
    if (data) {
        trackData('dom.referrer', window.location.href.length > 0 ? window.location.href : 'https://www.tui.com/flug', false, false);
        trackData('nbso_price_update', generateUrlParamsString(data), true, true);
    }
};

/**
 * Returns a comma separated list for the given array.
 * @param data Array of strings or a single string.
 */
export const joinTrackingData = (data) => {
    return Array.isArray(data) ? data.join() : data;
};
