export default function gebecoTrips(config) {
    const isRoundTripPage = (document.location.href.indexOf('rundreisen/') !== -1);

    if (isRoundTripPage) {
        const tripsModule = document.createElement('script');
        tripsModule.src = config.getGebecoSrcUrl();
        tripsModule.async = true;

        document.head.appendChild(tripsModule);
    }
}
