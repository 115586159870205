import * as toggles from '../toggles';
import * as util from '../util';
import * as mojoService from '../elchspucke-tuicom.service';

// STORY-14225

const COOKIE_NAME = '_vis_opt_exp_pay400_combi';
const START_DATE = new Date('2020-11-09').getTime();

export default () => {
    if (toggles.isActive('payments-test') && (Date.now() >= START_DATE) || (location.search.indexOf('STORY_14225=true') != -1)) {
        if (isInAudience()) {
            enableFeatureToggles();
        }
    }

    return 'payments-test';
};

const enableFeatureToggles = ()  => {
    util.readyStateComplete(() => {
        if (window.ibe && util.pageIs.BU1()) {
            window.ibe.featureEnabled.abTests.bu1PreselectSepaDebit = 1
            window.ibe.featureEnabled.abTests.bu1PaymentSequence = 1
        }
    });

    window.tuiCottonBall.subscribe('payments-test', '*', 'IBE checkout page rendered.', () => {
        window.tuiCottonBall.publish('IBE', 'billing-details', 'select-payment-method', 'SepaDebit');
    });
};

const setCookie = (cookieValue) => {
    const date = new Date();
    const daysToExpire = 365;

    date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000));

    document.cookie = COOKIE_NAME + "=" + cookieValue + "; expires=" + date.toGMTString();
};

const isInAudience = (sample = 50) => {
    const audienceCookie = mojoService.getCookie(COOKIE_NAME);
    const rand = util.getRandomInt(1, 100);

    let result = false;

    if (audienceCookie) {
        return audienceCookie === '2' ? true : false;
    }

    if (rand < sample) {
        result = true;
    }

    setCookie(result ? '2' : '1');
    return result;
};
