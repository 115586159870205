import FlightBanner from './tfm-mojo-banner-tracking.js';
import setupTFMTracking from './tfm-mojo-ibe-tracking.js';
import setupTFATracking from './tfa-tracking.js';
import Adform from './tfm-mojo-adform.js';


export default function () {
    FlightBanner();
    setupTFMTracking();
    setupTFATracking();
    Adform();
}
