import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';
import { TuiOverlay } from '../../overlay.service';
import { createHTMLNode, extractDateStringWithResetTimezone } from '../../util.js';

const injectPriceCalendarBanner = () => {
    var priceCalendarWrapper = document.querySelector('#tuiFilter .tui-price-calendar');
    var priceCalendarBannerElement = document.getElementById('tui-price-calendar-banner');

    if (priceCalendarWrapper && !priceCalendarBannerElement) {
        priceCalendarBannerElement = createPriceCalendarBanner('desktop');
        elchspuckeTuicomService.insertAsFirstChildIfExists(priceCalendarBannerElement, priceCalendarWrapper);
    } else if (!priceCalendarBannerElement) {
        var tuiFilterbarElement = document.getElementById('tuiFilter');
        if (tuiFilterbarElement) {
            priceCalendarBannerElement = createPriceCalendarBanner('desktop');
            elchspuckeTuicomService.insertAsFirstChildIfExists(priceCalendarBannerElement, tuiFilterbarElement);
        }
    }

    var priceCalendarBannerMobileElement = document.getElementById('tui-price-calendar-mobile-banner');
    if (!priceCalendarBannerMobileElement) {
        var tuiContentHeadElement = document.getElementById('tuiContentHead');
        if (tuiContentHeadElement) {
            priceCalendarBannerMobileElement = createPriceCalendarBanner('mobile');
            elchspuckeTuicomService.insertAfter(priceCalendarBannerMobileElement, tuiContentHeadElement);
        }
    }
}

const createPriceCalendarBanner = (bannerView) => {
    var priceCalendarBannerElement = document.createElement('div');
    priceCalendarBannerElement.setAttribute('id', 'tui-price-calendar-banner__container');
    var viewClass = 'tui-hidden-xs tui-hidden-sm';
    var viewId = 'tui-price-calendar-banner';
    if (bannerView === 'mobile') {
        viewClass = 'tui-hidden-md tui-hidden-lg';
        viewId = 'tui-price-calendar-mobile-banner';
    }

    var bannerHtml = `
        <div id="${viewId}" class="tui-row ${viewClass}">
            <section class="tui-well">
                <img class="tui-price-calendar-banner__image" src="https://cloud.tui.com/ui/ui-assets/v1/pictograms/pricecalendar.svg">
                <div class="price__text mobile">
                    <h3>Preiskalender</h3>
                    <a href="#" class="tui-price-calendar-banner">Jetzt günstigstes Angebot finden <ui-symbol symbol="chevron"></ui></a>
                </div>
                <div class="price__text desktop">
                    <h3>Preiskalender <ui-symbol symbol="chevron"></ui></h3>
                    <span>Jetzt Preise vergleichen und günstigstes Angebot finden</span>
                </div>
            </section>
        </div>`;

    priceCalendarBannerElement.innerHTML = bannerHtml;
    priceCalendarBannerElement.onclick = function () { window.tuiCottonBall.pleaseTakeCareOf('price-calendar', 'modal', 'The package pricematrix-banner was clicked.', {}) };

    return priceCalendarBannerElement;
}

const loadPriceCalendarMFE = (awsCdnUrl) => {
    new Promise((resolve, reject) => {
        if (document.getElementById('price-calendar-script')) {
            return resolve();
        }

        const $componentJs = document.createElement('script');
        $componentJs.setAttribute('src', `${awsCdnUrl}price-calendar/v1/price-calendar.iife.js`);
        $componentJs.setAttribute('type', 'module');
        $componentJs.setAttribute('id', 'price-calendar-script');

        $componentJs.onload = () => resolve();
        $componentJs.onerror = (error) => reject(error);

        document.body.appendChild($componentJs);
    })
    // eslint-disable-next-line no-console
    .catch((error) => console.error('price-calendar script could not be loaded', error));
}

const loadHolidayOfferFilterMFE = (awsCdnUrl) => {
    new Promise((resolve, reject) => {
        if (document.getElementById('holiday-offer-filter-script')) {
            return resolve();
        }

        const $componentJs = document.createElement('script');
        $componentJs.setAttribute('src', `${awsCdnUrl}holiday-offer-filter/v2/holiday-offer-filter.iife.js`);
        $componentJs.setAttribute('type', 'module');
        $componentJs.setAttribute('id', 'holiday-offer-filter-script');

        $componentJs.onload = () => resolve();
        $componentJs.onerror = (error) => reject(error);

        document.body.appendChild($componentJs);
    })
    // eslint-disable-next-line no-console
    .catch((error) => console.error('holiday-offer-filter script could not be loaded', error));
}

const updateFilters = (data) => {
    overlayElement && overlayElement.hide();
    const {offer, filterState} = data;
    if (offer) {
        const filters = {
            startDate: offer.startDate,
            endDate: offer.endDate,
            duration: offer.duration?.toString(),
            roomTypes: filterState.roomTypes,
            roomTypeOpCodes: filterState.roomTypeOpCodes,
            boardTypes: filterState.boardTypes,
            earlyBird: filterState.extraTypes.includes('EARLY-BIRD'),
            roomSpecificAttributes: filterState.viewTypes,
            departureAirports: filterState.airports,
            airlines: filterState.airlines,
            maxStopOver: filterState.stopovers,
            departureMinTime: roundTime(filterState.flightThereTime.split(',')[0]),
            departureMaxTime: roundTime(filterState.flightThereTime.split(',')[1]),
            returnMinTime: roundTime(filterState.flightReturnTime.split(',')[0]),
            returnMaxTime: roundTime(filterState.flightReturnTime.split(',')[1])
        }
    
        window.tuiCottonBall.broadcast('IBE', 'New filters were set.', filters );
    }
}

const roundTime = (time) => {
    if (!time) {
        return;
    }
    time = time.substring(0,5);
    let [hours, minutes] = time.split(':')

    if (+hours === 23 && +minutes === 59) {
        return time;
    } else if (+minutes < 30) {
        return `${hours}:00`;
    } else {
        return `${hours}:30`;
    }
} 

const buildFiltersFromIbeState = (ibeState) => {
    console.log('buildFiltersFromIbeState', ibeState);
    const {filters} = ibeState;
    return {
        pricing: ibeState.viewSettings.showTotalPrice ? 'TOTAL' : 'PER_PERSON',
        stopovers: filters.maxStopOver?.toString() || '',
        roomTypes: filters.roomTypeOpCodes?.length > 0 ? [] : filters.roomTypes,
        roomTypeOpCodes: filters.roomTypeOpCodes,
        boardTypes: filters.boardTypes,
        extraTypes: filters.earlyBird ? ['EARLY-BIRD']:[],
        viewTypes: filters.roomSpecificAttributes,
        airports: filters.departureAirports,
        airlines: filters.airlines,
        flightThereTime: [filters.departureMinTime, filters.departureMaxTime].join(','),
        flightReturnTime: [filters.returnMinTime, filters.returnMaxTime].join(','),
        transferIncluded: false
    }
}

const buildPriceCalendarElement = (tenant, locale, ibeState) => {
    //console.log('ibeState', ibeState);
    const {hotel, filters} = ibeState;

    let priceCalendarElement = document.querySelector('tui-price-calendar');

    if (!priceCalendarElement) {
        priceCalendarElement = createHTMLNode('tui-price-calendar');
    }
    priceCalendarElement.setAttribute('locale', locale);
    priceCalendarElement.setAttribute('tenant', tenant);
    priceCalendarElement.setAttribute('data-mode', 'light');
    priceCalendarElement.setAttribute('data-theme', 'tuiLight');
    priceCalendarElement.setAttribute('giata-id', hotel.giata);
    priceCalendarElement.setAttribute('searchscope', filters.searchScope);
    priceCalendarElement.setAttribute('start-search-date', extractDateStringWithResetTimezone(filters.startDate));
    priceCalendarElement.setAttribute('end-search-date', extractDateStringWithResetTimezone(filters.endDate));
    priceCalendarElement.setAttribute('duration', elchspuckeTuicomService.goAndHandlePTH4Durations(filters.duration, filters.startDate, filters.endDate));

    const {travellers} = filters;
    priceCalendarElement.setAttribute('adults', travellers[0].adults);
    priceCalendarElement.setAttribute('children-ages', travellers[0].children.join(','));

    holidayOfferFilterElement = priceCalendarElement.querySelector('tui-holiday-offer-filter');
    if (holidayOfferFilterElement){
        priceCalendarElement.removeChild(holidayOfferFilterElement);
    }
    holidayOfferFilterElement = buildHolidayOfferFilterElement(tenant, locale, ibeState);
    priceCalendarElement.appendChild(holidayOfferFilterElement);

    return priceCalendarElement;
}

const buildHolidayOfferFilterElement = (tenant, locale, ibeState) => {
    const {hotel, filters} = ibeState;

    let priceCalendarElement = document.querySelector('tui-holiday-offer-filter');

    if (!priceCalendarElement) {
        priceCalendarElement = createHTMLNode('tui-holiday-offer-filter');
    }
    priceCalendarElement.setAttribute('locale', locale);
    priceCalendarElement.setAttribute('tenant', tenant);
    priceCalendarElement.setAttribute('data-mode', 'light');
    priceCalendarElement.setAttribute('data-theme', 'tuiLight');
    priceCalendarElement.setAttribute('giata-id', hotel.giata);
    priceCalendarElement.setAttribute('searchscope', filters.searchScope);
    priceCalendarElement.setAttribute('start-search-date', extractDateStringWithResetTimezone(filters.startDate));
    priceCalendarElement.setAttribute('end-search-date', extractDateStringWithResetTimezone(filters.endDate));
    priceCalendarElement.setAttribute('duration', elchspuckeTuicomService.goAndHandlePTH4Durations(filters.duration, filters.startDate, filters.endDate));

    const {travellers} = filters;
    priceCalendarElement.setAttribute('adults', travellers[0].adults);
    priceCalendarElement.setAttribute('children-ages', travellers[0].children.join(','));

    return priceCalendarElement;
}

const updateFiltersOnPriceCalendar = (filters) => {
    console.log('updateFiltersOnPriceCalendar', JSON.parse(JSON.stringify(filters)));
    // TODO: we can not ensure that the object coming from one component is valid for the other component, so it should be transformed
    priceCalendarElement.setFilter(filters);
}

const updateFiltersOnHolidayOfferFilter = (filters) => {
    console.log('updateFiltersOnHolidayOfferFilter', filters, JSON.parse(JSON.stringify(filters)));
    // TODO: we can not ensure that the object coming from one component is valid for the other component, so it should be transformed
    holidayOfferFilterElement.setState(filters);
}

let overlayElement = null;
let priceCalendarElement = null;
let holidayOfferFilterElement = null;
let currentFilters = null;
const openPriceCalendar = (tenant, locale, ibeState) => {
    currentFilters = buildFiltersFromIbeState(ibeState);
    const title = 'Preiskalender';
    const wrapper = createHTMLNode('div');
    wrapper.style.height = '100%'
    priceCalendarElement = buildPriceCalendarElement(tenant, locale, ibeState);
    wrapper.appendChild(priceCalendarElement);
    
    overlayElement && overlayElement.destroy();
    overlayElement = new TuiOverlay({ title, contentNode: wrapper, overlayId: 'price-calendar-overlay' });
    overlayElement.show();
}

const setCurrentFilters = () => {
    console.log("setCurrentFilters", JSON.parse(JSON.stringify(currentFilters)));
    holidayOfferFilterElement.setState(currentFilters);
    holidayOfferFilterElement.submit();
}

export { 
    injectPriceCalendarBanner,
    loadPriceCalendarMFE, 
    loadHolidayOfferFilterMFE,
    updateFilters, 
    updateFiltersOnPriceCalendar,
    updateFiltersOnHolidayOfferFilter,
    openPriceCalendar,
    setCurrentFilters
}
