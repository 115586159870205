import { trackData, trackEvent } from '../../elchspucke-tuicom.service';
import { joinTrackingData } from './service';

/**
 * Set up tracking for TFA
 * @param tfaData Raw tfa data object from tfa seats component.
 * @returns Object containing different seat tracking arrays for tealium.
 */
export const getTrackingSeats = (tfaData) => {
    const selectedSeats = [];
    if (tfaData && tfaData.flights) {
        tfaData.flights.forEach(tfaFlight => {
            tfaFlight.passengers.forEach(passenger => {
                if (passenger.seat) selectedSeats.push(passenger.seat);
            });
        });
    }

    let product_name = [];
    let product_code = [];
    let product_type = [];
    let product_quantity = [];
    let product_price = [];

    selectedSeats.forEach(seat => {
        if (seat) {
            product_name.push(seat.seatCategory);
            product_code.push(seat.row + seat.column);
            product_type.push('seat-book');
            product_quantity.push(1);
            product_price.push(seat.price.amount);
        }
    });

    return { product_name, product_code, product_type, product_quantity, product_price };
};

export default () => {
    // NBSO-2619: track seat selection on bu1
    window.tuiCottonBall.subscribe('flightancillarywebcomponent', '*', 'seatSelectionFinished', (c, s, e, tfaData) => {
        // NBSO-2618: if seat data available, concat with the default flight products like flight or infant
        const trackSeats = getTrackingSeats(tfaData);
        
        trackData('product_name', joinTrackingData(trackSeats.product_name), false, false);
        trackData('product_code', joinTrackingData(trackSeats.product_code), false, false);
        trackData('product_price', joinTrackingData(trackSeats.product_price), false, false);
        trackData('product_quantity', joinTrackingData(trackSeats.product_quantity), false, false);
        trackData('product_type', joinTrackingData(trackSeats.product_type), false, false);

        trackEvent('feature', 'event', 'seatSelectionFinished', true);
    });
}
