/* global utag_data */

import * as mojoService from '../elchspucke-tuicom.service';

export default function() {
    /**
     * Tracks selected insurance plan
     * @param  {string} component the component raising the event
     * @param  {string} scope     the scope the event is raised in
     * @param  {string} event     the name of the event
     * @param  {object} data      { position: <number>, name: <string>, price: <number> }
     */
    function trackInsurancePlanSelected(component, scope, event, data) {
        const { position, name, price } = data;
        
        mojoService.trackEvent('feature', `${position}_${name}`, 'select', price);
    }

    /**
     * Tracks insurance terms agreement
     * @param  {string} component the component raising the event
     * @param  {string} scope     the scope the event is raised in
     * @param  {string} event     the name of the event
     * @param  {object} data      { accepted: <boolean> }
     */
    function trackInsuranceTermsAgreement(component, scope, event, data) {
        const { accepted } = data;
        
        if (accepted) {
            mojoService.trackEvent('feature', 'insurance_agreement', 'check', 0);
        }
    }

    /**
     * Tracks payment changed to tui-card
     * @param  {string} component the component raising the event
     * @param  {string} scope     the scope the event is raised in
     * @param  {string} event     the name of the event
     * @param  {object} data      { type: <'TC'|'TG'> }
     */
    function trackPaymentChangedToTuicard(component, scope, event, data) {
        const { type } = data;
        
        mojoService.trackEvent('bu1', `TC_${type}`, 'choose', 1);
    }

    /**
     * Tracks booked vacation with insurance
     * @param  {string} component the component raising the event
     * @param  {string} scope     the scope the event is raised in
     * @param  {string} event     the name of the event
     * @param  {object} data      { name: <string>, price: <number> }
     */
    function trackBookedVacationWithInsurance(component, scope, event, data) {
        const { name, price } = data;
        
        utag_data.insurance_name = name;
        utag_data.insurance_price = price;
    }

    window.tuiCottonBall.cuddleMeIf('IBE', 'BU1', 'insurance plan selected', trackInsurancePlanSelected);
    window.tuiCottonBall.cuddleMeIf('IBE', 'BU1', 'insurance terms agreement', trackInsuranceTermsAgreement);
    window.tuiCottonBall.cuddleMeIf('IBE', 'BU1', 'payment changed to tui-card', trackPaymentChangedToTuicard);
    window.tuiCottonBall.cuddleMeIf('IBE', 'BU3', 'booked vacation with insurance', trackBookedVacationWithInsurance);

    return 'insurance-tracking'
}
