import * as service from './service.js';

export default function (config) {
    window.tuiCottonBall.subscribe(
        'tui-room-panel',
        '*',
        'Finished initializing the angular app.',
        function (component, scope, event, data) {
            if (data.stepNumber === 4) {
                service.loadImageGalleryMFE(config.getAWSCdnUrl());
            }
        }
    );

    document.addEventListener('tui-room-panel.image-clicked', (e) => {
        service.openImageGallery(config.getTenant(), e.detail);
    });

    window.tuiCottonBall.subscribe('tui-room-panel', '*', 'IBE search result rendered.', function (a, b, c, data) {
        if (data.stepNumber === 4) {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
                service.updateRoomPanel({ ibeState });
            });
        }
    });

    return 'tui-room-panel';
}
