import * as ElchspuckeService from '../../elchspucke-tuicom.service.js';
import * as LoginService from './service.js';
import { pageIs } from '../../util.js';
import * as toggles from '../../toggles.js';

export default function () {
    /**
     * The following code is used to prepare the mojo for upcomming changes made to the template from the rTUI.
     * The template will contain skeletons of the components used in the top navigation.
     * The mojo will check for a class that is just present in the upcomming changes.
     * When this class is not present, the mojo will fallback to the previous.
     */

    const templateHasComponentSkeletons = document.querySelector('#tuiMeta .top-nav__menu.js-has-component-skeletons');
    let loginMetaNavPosition;
    let loginNavItem;
    if (templateHasComponentSkeletons) {
        loginNavItem = document.querySelector('#tuiMeta .top-nav__menu .tui-component-login');
        if (loginNavItem) {
            loginMetaNavPosition = loginNavItem.parentNode;
            loginNavItem.setAttribute('data-label', 'myTUI');
            loginNavItem.setAttribute('data-tenant', 'TUICOM');
        }
    } else {
        loginMetaNavPosition = document.querySelector('#tuiMeta .top-nav__menu li:nth-child(6)');
    }
    if (loginMetaNavPosition) {
        if (!templateHasComponentSkeletons) {
            loginMetaNavPosition.innerHTML =
                '<div class="top-nav__item-position / b2 u-pos-rel / tui-component tui-component-login tui-login-menu-item tui-login-fallback" data-theme="tuiLight" data-event-scope="login" data-tenant="TUICOM" data-lang="de_DE" data-visible="true" data-label="myTUI" data-authorities="MEINETUI,DUMMY" data-show-tooltip="false"></div>';
        }

        LoginService.initialize(loginMetaNavPosition);

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Login state label was clicked.', function () {
            LoginService.openDropdown();
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'User logged in.', function () {
            LoginService.onLogin();
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Password forgotten.', function () {
            LoginService.onPasswordForgotten();
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Create account.', function () {
            LoginService.onGoToRegistration("register_TUI_login");
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'Open login overlay.', function () {
            LoginService.showOverlay();
        });

        window.tuiCottonBall.cuddleMeIf('login', 'login', 'User logout.', function () {});

        const registeredLoginComponents = [];
        window.tuiCottonBall.cuddleMeIf(
            'login',
            '*',
            'Register my component.',
            function (component, scope, event, data) {
                if (
                    typeof data.name === 'string' &&
                    registeredLoginComponents.indexOf(data.name) < 0 &&
                    data.name !== 'login'
                ) {
                    registeredLoginComponents.push(data.name);
                }
            }
        );

        // for A/B-Test
        window.addEventListener('storage',function(e){
            if (
                e.storageArea === localStorage && 
                localStorage.getItem('login-overlay-ab20258') && 
                !sessionStorage.getItem('tui-membership-overlay-seen')) {
                    window.tuiCottonBall.broadcast('login', 'login', 'Get login state.', {});
            } 
        });

        window.tuiCottonBall.cuddleMeIf(
            'login',
            'login',
            'The current users login state.',
            function (component, scope, event, data) {
                ElchspuckeService.setCurrentUser(data);

                if (
                    toggles.isActive('mytui-overlay') && 
                    !document.querySelector('.tui-membership__overlay') && 
                    !data.token && 
                    !LoginService.isMobileBreakpoint() && 
                    pageIs.home() && 
                    !sessionStorage.getItem('tui-membership-overlay-seen') &&
                    localStorage.getItem('login-overlay-ab20258') === 'true')
                {
                    const membershipWrapper = LoginService.membershipWrapper();
                    ElchspuckeService.insertAfter(membershipWrapper, loginNavItem.parentNode);
                    document.dispatchEvent(
                        new CustomEvent('tui-membership-overlay.login', {
                            bubbles: true,
                            composed: true,
                            detail: {
                                category: 'membership',                    
                                action: 'overlay-displayed',                    
                                label: '10sec'
                            }
                        })
                    );
                    sessionStorage.setItem('tui-membership-overlay-seen', true);
                }

                LoginService.addDropdownMenu(loginMetaNavPosition);
                registeredLoginComponents.forEach((componentName) => {
                    window.tuiCottonBall.broadcast(componentName, 'The current users login state.', data);
                });

                document.dispatchEvent(
                    new CustomEvent('tui-favorites.AuthenticationStateChanged', {
                        bubbles: true,
                        composed: true,
                        detail: {
                            token: data.token || null
                        }
                    })
                );
            }
        );

        window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
    }

    const searchParams = new URL(document.location).searchParams;
    if (searchParams) {
        const showLoginVerification = searchParams.get('registered');
        if (JSON.parse(showLoginVerification) === 1) {
            LoginService.showVerification();
        }
    }

    window.addEventListener("resize", function() {
        let overlay = document.querySelector('.tui-membership__overlay');
        if (overlay) {
            if (this.document.body.clientWidth < 992) {
                overlay.style.visibility = 'hidden';
                overlay.style.width = '0';
            } else {
                overlay.style.visibility = 'visible';
                overlay.style.width = '220.25px';
            }
        }
    });

    if (document.querySelector('.js-toggle-main-nav')) {
        document.querySelector('.js-toggle-main-nav').addEventListener('click', () => {
            const dropdown = document.querySelector('.tui-login-dropdown');
            const bodyItem = document.querySelector('body');
            const loginOverlay = document.querySelector('.tui-login-overlay');
            if (dropdown.style.display === 'block') {
                dropdown.style = null;
                if (bodyItem.classList.contains('dropdown-list-active')) {
                    bodyItem.classList.remove('dropdown-list-active');
                }
            } else if (loginOverlay.classList.contains('shown')) {
                loginOverlay.classList.remove('shown');
            }
        });
    }

    return 'login';
}
