/* eslint-disable */
import { pageIs } from "../../util.js";
import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';
import * as toggles from '../../toggles';

const confirmationRenderedCallbackQueue = [];

/**
 * Tracks total revenue including all extras - promocode
 */
function trackTotalRevenue() {
    window.tuiCottonBall.cuddleMeIf('tracking', '*', 'IBE checkout confirmation page rendered.', () => {
        if (pageIs.BU3()) {
            const productPrice = window.utag_data.product_totalprice ? parseInt(window.utag_data.product_totalprice) : 0;
            const insurancePrice = window.utag_data.insurance_price ? parseInt(window.utag_data.insurance_price) : 0;
            const carsPrice = window.utag_data.cars_price ? parseInt(window.utag_data.cars_price) : 0;
            const seatPrice = window.utag_data.seat_price ? parseInt(window.utag_data.seat_price) : 0;
            const promocodePrice = window.utag_data.promocode_total ? parseInt(window.utag_data.promocode_total) : 0;
            const totalRevenue = productPrice + insurancePrice + carsPrice + seatPrice - promocodePrice;

            if (toggles.isActive('handle-bu-utag-view')) {
                elchspuckeTuicomService.trackData('total_revenue', totalRevenue);
            } else {
                elchspuckeTuicomService.trackData('total_revenue', totalRevenue, true, true);
            }
        }
    });
}

/**
 * Tracks permission events on BU1
 */
function trackPermissions() {
    if (pageIs.BU1()) {
        let result = {
            mail: false,
            phone: false,
            general: false
        };
        window.tuiCottonBall.cuddleMeIf('IBE', 'BU1', 'user changed marketing-contact agreement',
        function (a, b, c, d) {
            if (d && d.contactType) {
                result[d.contactType] = d.hasAgreed;
            }
            elchspuckeTuicomService.trackEvent('feature', 'permission', 'click', JSON.stringify(result));
        });
    }
}

function triggerUtagView() {
        const utagViewTriggered = [];
        let timerBu1;
        let timerBu3;

        const startTimer = function(pageType) {
            return setTimeout(() => {
                if (utagViewTriggered.indexOf(pageType) === -1) {
                    utag.view(utag.data || {});
                    // eslint-disable-next-line no-console
                    console.log('utag.view force triggerd', window.utag_data);
                }
            }, 4000);
        };

        window.tuiCottonBall.subscribe('tracking', 'BU1', 'Trigger utag view.', () => {
            utag.view(utag.data || {});
            utagViewTriggered.push('BU1');
            clearTimeout(timerBu1);
        });

        window.tuiCottonBall.subscribe('tracking', 'BU3', 'Trigger utag view.', () => {
            waitConfirmationRenderedEvent(() => {
                utag.view(utag.data || {});
                utagViewTriggered.push('BU3');
                clearTimeout(timerBu3);
            });
        });

        window.tuiCottonBall.subscribe('tracking', '*', 'IBE checkout price change rendered.', () => {
            utag.view(utag.data || {});
        });

        window.tuiCottonBall.subscribe('tracking', '*', 'IBE checkout page rendered.', () => {
            timerBu1 = startTimer('BU1');
        });

        window.tuiCottonBall.subscribe('tracking', '*', 'IBE checkout confirmation page rendered.', () => {
            waitConfirmationRenderedEvent(false, true);
            timerBu3 = startTimer('BU3');
        });
}

function waitConfirmationRenderedEvent(callback, trigger) {
    if (trigger === true) {
        confirmationRenderedCallbackQueue.forEach((queueEntry) => {
            queueEntry.call();
        })
    } else {
        if (callback !== false && typeof callback === 'function') {
            confirmationRenderedCallbackQueue.push(callback);
        }
    }
}

export default () => {
    trackTotalRevenue();
    trackPermissions();
    if (toggles.isActive('handle-bu-utag-view')) {
        triggerUtagView();
    }

    return 'tracking';
}
