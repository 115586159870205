import * as service from './service';

export default function () {
    let upsellNotificationHasBeenShown = false;
    let giataId = 0;
    let scrollListener = null;

    window.tuiCottonBall.subscribeToAllScopes('social-stats', 'IBE search result rendered.', function (component, scope, event, data) {
        if (data.stepNumber === 4) {
            if (data.searchScope === 'HOTEL') {
                giataId = data?.data?.hotel?.giataId
                if(!giataId){
                    console.warn('No giataId provided.')
                    return;
                }

                service.removeExistingSocialStats()
                service.injectSocialStatsWrapper()
                            
                const infoNav = document.querySelector('#info-nav')
                if(isInfoNavInPosition({infoNav})){
                    showUpsellNotification(data.searchScope.toLowerCase())
                    return;
                }
                
                scrollListener = document.addEventListener('scroll', () => {
                    if(isInfoNavInPosition({infoNav})){
                        showUpsellNotification(data.searchScope.toLowerCase())
                    }
                })
            }
        }
    });


    document.addEventListener('tui-social-notification.Rejected', (event) => {
        if(!giataId) return;
        if(event.detail.variant == 'top-hotel-package-share'){
            showLastBookedNotification(event.detail.type);
        }
    });

    document.addEventListener('tui-social-notification.Shown', (event) => {
        const {variant, text} = event?.detail

        window.utag_data['imp_socialstat'] = true; 
        window.utag.link({
            link_category: 'socialstat',
            link_action: 'display',
            link_type: variant,
            link_label: text,
            noninteraction: true
        });
    });


    const isInfoNavInPosition = ({infoNav}) => {
        const offsetRatio = (window.screen.height - infoNav.getBoundingClientRect().top) *  1 / window.screen.height;
        return offsetRatio > 0.5 && offsetRatio < 2
    }

    const showUpsellNotification = (type) => {
        if(upsellNotificationHasBeenShown) return false;
        service.injectNotification(giataId, "top-hotel-package-share", type, "tui-notification-upsell");
        service.showNotificationForGivenTime(5000, "tui-notification-upsell");
        document.removeEventListener('scroll', scrollListener);
        upsellNotificationHasBeenShown = true;
    } 

    const showLastBookedNotification = (type) => {
        service.injectNotification(giataId, "last-booked", type, "tui-notification-last-booked");
        service.showNotificationForGivenTime(5000, "tui-notification-last-booked");
        document.removeEventListener('scroll', scrollListener);
    } 
    
    return 'social-stats';
}
