import * as ElchspuckeService from '../../elchspucke-tuicom.service';

export default function () {

    // implemented component lifebuoy with tealium
    
    const removeNewsletterFromSiteFlap = () => {
        const newsletterFlap = document.querySelector('.newsletter-flap');
        
        if (newsletterFlap) {
            newsletterFlap.remove();
        } else {
            
            const style = document.createElement('style');
            style.innerHTML = `
                .newsletter-flap { display: none; }
            `;
            document.head.appendChild(style);
        }
    }
    
    // tracking

    window.addEventListener('lifebouy.Initialize', () => {        
        removeNewsletterFromSiteFlap();
    });

    window.addEventListener('lifebouy.ButtonClicked', (event) => {
        if (event?.detail?.name == 'open') { // Öffnen Rettungsring
            ElchspuckeService.trackEvent('lifebelt', 'lifebelt', 'open_lifebelt', ElchspuckeService.getPageName());
        }
    });

    window.addEventListener('lifebouy.ItemClicked', (event) => {
        if (event?.detail?.name) {
            ElchspuckeService.trackEvent('lifebelt', 'lifebelt', event.detail.name, ElchspuckeService.getPageName());
        }
    });

    return 'lifebuoy';
}
