import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service.js';

const getSocialStatsTag = (id, data) => {
    if (!id &&
        !data &&
        !data.reference
    ) {
        return null;
    }

    let html = document.createElement('tui-social-notification');
    html.classList.add('tui-component');
    html.classList.add('tui-social-stat');
    html.id = id;

    html.setAttribute('data-theme', data.theme || 'tui-light');
    html.setAttribute('data-legacy-font', '');
    html.setAttribute('variant', data.variant || 'last-booked');
    html.setAttribute('locale', data.locale || 'de-De');
    html.setAttribute('type', data.type || 'hotel');
    html.setAttribute('reference', data.reference);
    html.setAttribute('show', "false");

    return html
}

const injectSocialStatsWrapper = () => {
    const wrapper = document.createElement('div');
    wrapper.id = "tui-notification-wrapper"

    const socialStatsView = document.querySelector("ui-view[name='socialStats']")
    socialStatsView.appendChild(wrapper)
}

const injectNotification = (giataId, variant, type, id) => {
    if(!giataId) return;

    const wrapper = document.querySelector('#tui-notification-wrapper')
    if(!wrapper) return;

    const locale = elchspuckeTuicomService.getDomainLocale();
    const socialStatsData = {
        locale: locale.localeCode,
        reference: giataId,
        variant,
        type
    }
    const socialStatsTag = getSocialStatsTag(id, socialStatsData);
    wrapper.appendChild(socialStatsTag)
}

const removeExistingSocialStats = () => {
    const socialStatsView = document.querySelector("ui-view[name='socialStats']")
    const socialStatsComponent = document.querySelector('nsd-component-social-stats');
    
    socialStatsView.removeChild(socialStatsComponent);
}

const showNotificationForGivenTime = (time, id) => {
    const notification = document.querySelector(`#${id}`)
    const wrapper = document.querySelector('#tui-notification-wrapper')
    if(!notification || !wrapper) return;
    notification.classList.add("show")
    setTimeout(() => {
        wrapper.removeChild(notification);
    }, time)
}

export {
    injectSocialStatsWrapper,
    injectNotification,
    showNotificationForGivenTime,
    removeExistingSocialStats
};
