import * as elchspuckeTuicomService from '../../elchspucke-tuicom.service';
import * as service from './service.js';

export default function (config) {

    const dataLoaded = {
        priceCalendar: false,
        filter: false,
    }

    const loadInitState = () => {
        if (dataLoaded.priceCalendar && dataLoaded.filter) {
            service.setCurrentFilters();
            dataLoaded.filter = false;
            dataLoaded.priceCalendar = false;
        }
    }

    window.tuiCottonBall.subscribe('price-calendar', '*', 'Finished initializing the angular app.', function (component, scope, event, data) {
        if (data.stepNumber === 4) {
            service.injectPriceCalendarBanner();
            service.loadPriceCalendarMFE(config.getAWSCdnUrl());
            service.loadHolidayOfferFilterMFE(config.getAWSCdnUrl());
        }
    });

    document.addEventListener('tui-price-calendar.best-offer-selected', (e) => {
        service.updateFilters(e.detail);
        elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'apply', 1);
    });
    
    document.addEventListener('tui-holiday-offer-filter.submit', (e) => {
        // tracking removed, because it's triggered whenever price-calendar opens, but should be tracked only if the customer applied an filter manually
        //elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'apply_filter', 1);
        service.updateFiltersOnPriceCalendar(e.detail);
    });

    document.addEventListener('tui-holiday-offer-filter.data-loaded', () => {
        dataLoaded.filter = true;
        loadInitState();
    });

    // tracking
    document.addEventListener('tui-price-calendar.calendar-loaded', () => {
        dataLoaded.priceCalendar = true;
        loadInitState();
        elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'open', 1);
    });

    document.addEventListener('tui-price-calendar.offer-selected', () => {
        elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'choose', 1);
    });
    
    document.addEventListener('tui-price-calendar.selected-month-changed', (e) => {
        if (e.detail.direction === 'fwd') {
            elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'load_offers_right', 1);
        } else if (e.detail.direction === 'back') {
            elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'load_offers_left', 1);
        }
    });

    document.addEventListener('tui-price-calendar.filter-opened', () => {
        elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'open_filter', 1);
    });

    document.addEventListener('tui-price-calendar.filter-removed', (e) => {
        service.updateFiltersOnHolidayOfferFilter(e.detail.filterState);
        elchspuckeTuicomService.trackEvent('feature', 'preiskalender', 'remove_filter', e.detail.removed.label);
    });

    window.tuiCottonBall.subscribe('price-calendar', 'modal', 'The package pricematrix-banner was clicked.', function () {
        window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
            service.openPriceCalendar(config.getSearchDiggerTenant(), config.getLanguage(), ibeState);
        });
    });

    return 'price-calendar';
}
