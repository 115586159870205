import { getParameterByName } from '../../elchspucke-tuicom.service';
import { pageIs } from '../../util.js';

export default function() {
    const useHistoryBack = getParameterByName('historyBack') || getParameterByName('m300');

    if (!pageIs.PTH4() || useHistoryBack !== '1') {
        return false;
    }

    const styleEl = document.createElement('style');
    styleEl.innerHTML = `
        #tuiBreadcrumb, #tuiSteps { display: none; }
        .tui-history-back-wrapper { margin: 0 0 15px 0; }
        .tui-history-back-wrapper .tui-icon { color: #176599; }
        .tui-history-back-wrapper .tui-icon:before { font-weight: bold; }
        .tui-history-back-wrapper button { color: #176599; font-weight: bold; border: none; background: none; padding-left: 0; }
        .tui-history-back-wrapper button:active, .tui-history-back-wrapper button:focus { background: none; outline: none; border: none; }
    `;
    document.head.appendChild(styleEl);

    window.tuiCottonBall.cuddleMeIf('history-back', '*', 'Finished initializing the angular app.', function(){
        const tuiSteps = document.getElementById('tuiSteps');
        const historyBackBtn = document.getElementById('tui-history-back-back-button');

        if (tuiSteps && !historyBackBtn) {
            const wrapperEl = document.createElement('div');
            wrapperEl.setAttribute('class', 'tui-history-back-wrapper');

            wrapperEl.innerHTML = `
                <button id="tui-history-back-back-button">
                    <i class="tui-icon tui-icon--arrow-left" aria-hidden="true"></i>
                    zurück
                </button>
            `
            tuiSteps.parentNode.insertBefore(wrapperEl, tuiSteps);

            const createdBtn = document.getElementById('tui-history-back-back-button')
            if (createdBtn) {
                createdBtn.addEventListener('click', () => {
                    window.history.back();
                });
            }
        }
    });

    return 'history-back';
}
