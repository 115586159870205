/**
 * Show an Adform Tag on pt3 of TFM-IBE
 * 
 * FILE WILL BE REMOVED after move to Google AD
 *
 * TODO:    'hidden'-Attribute inappropriate for this purpose *,
 *          but we want to keep the adform-snippet out of this Mojo (no creation/destruction of HTMLElement)
 *          * https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/hidden
 *
 * TODO:    https://support.adform.com/documentation/good-to-know/asynchronous-tag-solution/
 *
 */
export default function () {
    window.tuiCottonBall.cuddleMeIf('tfm', '*', 'tfm page navigation.', (c, s, e, data) => {
        if (data) {
            const adformTags = document.getElementsByClassName('tui-tfm-adform');
            if (adformTags.length) {
                switch (data['page']) {
                    case 'search-direct':                      // tfm search opened with predefined search params
                    case 'search-results':                     // tfm search results due to user search
                        Array.prototype.forEach.call(adformTags, child => {
                            child.classList.remove('visually-hidden');
                        });
                        break;
                    default:
                        Array.prototype.forEach.call(adformTags, child => {
                            child.classList.add('visually-hidden');
                        });
                        break;
                }
            }
        }
    });
}
