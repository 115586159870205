import {
    insertAfter,
    insertBefore,
    trackEvent
} from '../../elchspucke-tuicom.service';

export default class service {

    static insertFacultativeTrips(giataId, regionName, regionCode) {
        // Insertpoint (other position on screens less than 768px)
        let insertPoint = document.querySelector('.ng-app-Checkout.ibe.ibe-Checkout.ibe-Checkout-index');
        let buttonStyle = '';

        if (window.innerWidth < 768) {
            const xsSelector = document.querySelectorAll('ibe-bookform-confirmation-infobox > section > p.bold');

            for (let i = 0; i < xsSelector.length; i++) {
                let text = [].reduce.call(xsSelector[i].childNodes, function (a, b) {
                    return a + (b.nodeType === 3 ? b.textContent : '');
                }, '');
                if (text === "Wir wünschen Ihnen eine gute Reise,Ihr TUI.com Team") {
                    insertPoint = xsSelector[i];
                }
            }
            buttonStyle = 'display: block;';
        }

        const trackingParams = () => {
            const params = [
                'contentid=tuicom_bu3-trips-banner',
                'utm_source=confirmation-page',
                'utm_medium=TUIcom',
                'utm_campaign=gotui-de',
                'utm_term=homepage',
                'utm_content=text'
            ];

            return params.join('&');
        };

        // musement wrapper
        const facultativeTripsWrapper = document.createElement('div');
        facultativeTripsWrapper.classList.add('facultative-trips-wrapper');

        // headline
        const headline = document.createElement('h1');
        headline.appendChild(document.createTextNode('Beliebte Ausflüge an Ihrem Reiseziel'));

        // musement all trips button
        const allTripsButton = document.createElement('div');
        allTripsButton.classList.add('tui-card-button');
        allTripsButton.setAttribute('style', 'margin: 20px 50px 0px; text-align: right;');

        const allTripsLink = document.createElement('a');
        allTripsLink.classList.add('tui-btn', 'tui-btn--lg', 'tui-btn--default');
        allTripsLink.href = `https://www.gotui.com/de/?msm_int_destination_code=${regionCode}&msm_int_hotel_code=${giataId}&${trackingParams()}`;
        allTripsLink.target = '_blank';
        allTripsLink.rel = 'noopener noreferrer';
        if (buttonStyle) {
            allTripsLink.setAttribute('style', `${buttonStyle}`);
        }
        allTripsLink.appendChild(document.createTextNode('Alle Ausflüge anzeigen'));

        // add tracking event
        allTripsLink.addEventListener("click", function(){
            trackEvent('feature', 'musement', 'select', regionName);
        });

        allTripsButton.appendChild(allTripsLink);

        // musement 6m component
        const facultativeTripsDiv = document.createElement('div');
        facultativeTripsDiv.classList.add('tui-component', 'tui-component-facultative-trips');
        facultativeTripsDiv.setAttribute('data-hotel-ids', "[" + giataId + "]");

        // append 6m component to wrapper
        facultativeTripsWrapper.appendChild(facultativeTripsDiv);

        insertAfter(facultativeTripsWrapper, insertPoint);

        window.tuiCottonBall.broadcast('cotton-ball', 'New TUI components.', {});
        window.tuiCottonBall.cuddleMeIf('facultative-trips', '*', 'A new musement slider has born.', function (component, scope, event, data) {
            if (data) {
                insertBefore(headline, facultativeTripsDiv);
                insertAfter(allTripsButton, facultativeTripsDiv);
            }
        });
    }

}
