import * as service from './service.js';

export default function (config) {
    const tenant = config.getTenant();

    window.tuiCottonBall.subscribe('recommendations', '*', 'IBE search result rendered.', function (component, scope, event, data) {
        service.removeRecommendations();

        if (data.stepNumber === 4) {
            window.tuiCottonBall.broadcast('IBE', 'Request IBE state.', (c, s, e, ibeState) => {
                service.injectRecommendations(config.getAWSApiUrl(), data, ibeState, tenant);
            });
        }
    });

    window.tuiCottonBall.subscribe('recommendations', 'crystalCardsOnHome', 'Load crystal-ball hotels.', function(component, scope, event, data){
        if (!data.properties) {
            data.properties = {
                searchScope: 'PACKAGE'
            };
        }

        if ('topHotels' === data.type) {
            service.loadTopHotelsHotelcard(config.getAWSApiUrl(), data);
        } else if ('topRegion' === data.type) {
            service.loadTopRegionHotelcard(config.getAWSApiUrl(), data);
        } else if ('lastBooked' === data.type) {
            service.loadLastBookedHotelcard(config.getAWSApiUrl(), data);
        }
    });

    return 'recommendations';
}
