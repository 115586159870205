export default function () {

    window.tuiCottonBall.subscribe('utag', '*', 'IBE search result rendered.', function() {

        window.tuiCottonBall.publish('utag', '*', 'ISC banner can be injected.', {});

    });

    return 'utag';
}
