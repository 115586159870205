import * as OfferComparatorService from "../../offer-comparator/1.0.0/service";
import * as ElchspuckeService from "../../elchspucke-tuicom.service";

export default function (config) {
    const plattformUrl = config.getPlattformHost();

    window.tuiCottonBall.subscribeToAllScopes('offer-comparator', 'instance.rendered', (component, scope, event, payload) => {
        let urls = payload.comparisonItems.map((comparisonItem) => {
            return OfferComparatorService.generateCallToActionPTH4(plattformUrl, comparisonItem);
        });
        payload.setCallToActionUrls(urls);
    });

    window.tuiCottonBall.subscribe('offer-comparator', 'favorites-comparator', 'callToAction.pushed', (component, scope, event, payload) => {
        if (payload.hotelOffer && payload.hotelOffer.hotel) {
            ElchspuckeService.trackEvent('feature', 'savesearch-comparator', 'apply', payload.hotelOffer.hotel.giataId);
        }
    });

    return 'offer-comparator';
}
